import React, {useContext, useState} from "react"
import { useTranslation } from 'react-i18next'
import { multiStepContext } from "../Step-Context"
import {MdOutlineArrowForwardIos} from 'react-icons/md'
import '../../application/Loading.css'

export default()=>{
    const {setStep, userData, setUserData, setIsPersonal} = useContext(multiStepContext)
    const {t,i18n} = useTranslation()
    const browserInfo = () =>{
        const FingerprintJS = require('@fingerprintjs/fingerprintjs')
        const fpPromise = FingerprintJS.load()
        
        fpPromise
        .then(fp => fp.get())
        .then(result => setUserData({...userData, "browserInfo" : JSON.stringify(result)}))
    }
    return(
        <div className="flex w-[100%] h-[100%] mt-20 justify-center">
            <div className="grid w-[70%] lg:w-[70%] gap-5">
                <span className="text-[14px] font-customRegular text-gray-900 text-center">{t('loan.lender-type-title')}</span>
            <button class="bg-white border text-gray-900 font-customBold py-2 px-4 rounded shadow-md">
                <div className="flex items-center justify-between"
                onClick={()=>(
                    setStep(2),
                    setIsPersonal(true),
                    browserInfo()
                )}>
                    <img src={require('../../../../assets/images/user.png')} alt='user'/>
                    {t('loan.lender-type-individual')}
                    <MdOutlineArrowForwardIos/>
                </div>
            </button>

            <button class="bg-white border text-gray-900 font-customBold py-2 px-4 rounded shadow-md">
                <div className="flex items-center justify-between"
                onClick={()=>(
                    setStep(3),
                    setIsPersonal(false),
                    browserInfo()
                )}>
                    <img src={require('../../../../assets/images/user-group.png')} alt='user'/>
                    {t('loan.lender-type-corporate')}
                    <MdOutlineArrowForwardIos/>
                </div>
            </button>
            </div>
        </div>
    )
}