import { useTranslation } from 'react-i18next'

export default()=>{
    const {t,i18n} = useTranslation()
    return(
        <>
        <div style={{
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundImage: `url(${require(`../../assets/images/career-bg.png`)})`
            }} className='hidden sm:flex w-[100%] h-[350px] justify-center'>
                <div className='flex w-[100%] pc-3:w-[1366px]'>
                    <div className='w-[50%] flex items-end justify-center'>
                        <img src={require('../../assets/images/careeer-logo.png')} alt='home-download' className='object-cover w-[450px]'/>
                    </div>
                    <div className='w-[50%] flex items-center'>
                        <div className='w-[70%] h-fit'>
                            <h1 className='leading-tight text-[32px] text-colorBlueDark font-customExtraBold mb-3'>{t('poster.title')}</h1>
                            <p className='text-[14px] text-colorBlueDark font-customRegular mb-5'>{t('poster.text')}</p>
                            <div className='flex mt-3 gap-3 w-fit text-[16px] bg-colorOrangeDark p-3 text-white rounded-lg'>
                                <a href='https://www.jobstreet.co.id/id/job-search/jobs-at-pt-pendanaan-teknologi-nusa/' target='_blank'>
                                    {t('poster.button')}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundImage: `url(${require(`../../assets/images/career-mobile-footer.png`)})`
            }} className='block sm:hidden w-[100%] h-fit px-5 py-14'>
                <div className='w-[100%]'>
                    <img src={require('../../assets/images/careeer-logo.png')} alt='home-download' className='object-cover w-[450px]'/>

                    <div className='relative w-[100%]'>
                        <div className='w-[100%] h-fit pt-5'>
                            <h1 className='leading-tight text-[32px] text-colorBlueDark font-customExtraBold mb-3'>{t('poster.title')}</h1>
                            <p className='text-[14px] text-colorBlueDark font-customRegular mb-5'>{t('poster.text')}</p>
                            <div className='flex mt-3 gap-3 w-fit text-[16px] bg-colorOrangeDark p-3 text-white rounded-lg'>
                                <a href='https://www.jobstreet.co.id/id/job-search/jobs-at-pt-pendanaan-teknologi-nusa/' target='_blank'>
                                    {t('poster.button')}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}