import React, { useState } from 'react'
import Application from './Application'

export const multiStepContext = React.createContext()
const StepContext=()=>{
    const [currentStep, setStep] = useState(1)
    const [userData, setUserData] = useState([])
    const [finalData, setFinalData] = useState([])
    const [isPersonal, setIsPersonal] = useState(false)
    return(
        <div>
        <multiStepContext.Provider value={{currentStep, setStep, userData, setUserData, finalData, setFinalData
        , isPersonal, setIsPersonal}}>
            <Application/>
        </multiStepContext.Provider>
        </div>
    )
}

export default StepContext