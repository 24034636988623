import React from 'react'
import { useTranslation } from 'react-i18next'
import './Lender.css'
import SwiperCore, { Autoplay, Pagination } from 'swiper'
import 'swiper/css'
import { FaArrowRight } from 'react-icons/fa'
import {Link, useMatch, useResolvedPath} from 'react-router-dom'

export default ()=>{
    const {t,i18n} = useTranslation()
    SwiperCore.use([Autoplay, Pagination])
    return(
        <>
        <div className='grid sm:flex justify-center w-[100%] h-fit px-5 lg:pl-16 pt-24 pb-5 lg:py-0 bg-gradient-to-b from-[#F4BA54] via-[#E0A234] to-[#E77B30]'>
            <div className='flex sm:hidden w-[100%] items-center lg:items-end justify-center lg:justify-end'>
                <img src={require('../../assets/images/lender-image.png')} className='w-[100%] object-cover'/>
            </div>
            <div className='w-[100%] pc-3:w-[1366px] flex mt-10 sm:mt-32'>
                <div className='flex w-[100%] sm:w-[50%] items-center leading-tight'>
                    <div className='w-[100%] sm:w-[70%]'>
                        <h1 className='mt-3 text-[#204a63] text-[40px] font-customExtraBold'>{t('lender.banner.title')}</h1>
                        <span className='mt-3 text-[#327298] text-[14px] font-customRegular'>{t('lender.banner.text')}</span>

                        <CustomLink to = '/lender-app' className='flex w-[100%] bg-[#327298] hover:opacity-90 active:bg-[#2c5e7a] text-white font-customBold text-[14px] py-3 rounded-md items-center justify-center my-10'>{t('lender.button')} &nbsp;<FaArrowRight/></CustomLink>
                    </div>
                </div>

                <div className='hidden sm:flex w-[50%] items-center lg:items-end justify-center lg:justify-end'>
                    <img src={require('../../assets/images/lender-image.png')} className='w-[100%] object-cover'/>
                </div>
            </div>
        </div>

        <div className='hidden sm:flex w-[100%] h-fit bg-white py-10 justify-center'>
            <div className='w-[100%] pc-3:w-[1366px]'>
            <h3 className='mb-5 text-[#000] text-[30px] font-customExtraBold text-center'>{t('lender.title1')}</h3>

            <div className='flex gap-10 px-5 lg:px-16 justify-center'>
                <div className='w-[210px] bg-white drop-shadow-xl rounded-2xl'>
                    <div className='flex h-[200px] bg-[#E0A234] rounded-t-2xl p-5 justify-center'>
                        <img src={require(`../../assets/images/frame-6.png`)}/>
                    </div>

                    <h3 className='text-[14px] text-center font-customBold mt-3 mb-3 px-3'>{t('lender.title1.title1')}</h3>
                    <p className='text-[12px] text-center font-customRegular pb-5 px-2'>{t('lender.title1.text1')}</p>
                </div>

                <div className='w-[210px] bg-white drop-shadow-xl rounded-2xl'>
                    <div className='flex h-[200px] bg-[#E0A234] rounded-t-2xl p-5 justify-center'>
                        <img src={require(`../../assets/images/frame-15.png`)}/>
                    </div>

                    <h3 className='text-[14px] text-center font-customBold mt-3 mb-3 px-3'>{t('lender.title1.title2')}</h3>
                    <p className='text-[12px] text-center font-customRegular pb-5 px-2'>{t('lender.title1.text2')}</p>
                </div>

                <div className='w-[210px] bg-white drop-shadow-xl rounded-2xl'>
                    <div className='flex h-[200px] bg-[#E0A234] rounded-t-2xl p-5 justify-center'>
                        <img src={require(`../../assets/images/frame-5.png`)}/>
                    </div>

                    <h3 className='text-[14px] text-center font-customBold mt-3 mb-3 px-3'>{t('lender.title1.title3')}</h3>
                    <p className='text-[12px] text-center font-customRegular pb-5 px-2'>{t('lender.title1.text3')}</p>
                </div>

                <div className='w-[210px] bg-white drop-shadow-xl rounded-2xl'>
                    <div className='flex h-[200px] bg-[#E0A234] rounded-t-2xl p-5 justify-center'>
                        <img src={require(`../../assets/images/frame-12.png`)}/>
                    </div>

                    <h3 className='text-[14px] text-center font-customBold mt-3 mb-3 px-3'>{t('lender.title1.title4')}</h3>
                    <p className='text-[12px] text-center font-customRegular pb-5 px-2'>{t('lender.title1.text4')}</p>
                </div>
            </div>
            </div>
        </div>

        {/* Mobile */}        
        <div className='block lg:hidden w-[100%] h-fit bg-white px-5 py-10'>
            <h3 className='mb-5 text-[#000] text-[30px] font-customExtraBold text-center'>{t('lender.title1')}</h3>

            <div className='flex w-full bg-white drop-shadow-xl rounded-2xl'>
                <div className='flex w-[50%] h-[200px] bg-[#E0A234] rounded-l-2xl p-5 justify-center'>
                    <img src={require(`../../assets/images/frame-6.png`)}/>
                </div>

                <div className='flex w-[50%] justify-center items-center'>
                    <div className='h-fit px-3'>
                        <h3 className='text-[14px] text-center font-customBold mb-3'>{t('lender.title1.title1')}</h3>
                        <p className='text-[12px] text-center font-customRegular'>{t('lender.title1.text1')}</p>
                    </div>
                </div>
            </div>

            <div className='flex w-full bg-white drop-shadow-xl rounded-2xl mt-5'>
                <div className='flex w-[50%] justify-center items-center'>
                    <div className='h-fit px-3'>
                        <h3 className='text-[14px] text-center font-customBold mb-3'>{t('lender.title1.title2')}</h3>
                        <p className='text-[12px] text-center font-customRegular'>{t('lender.title1.text2')}</p>
                    </div>
                </div>

                <div className='flex w-[50%] h-[200px] bg-[#E0A234] rounded-r-2xl p-5 justify-center'>
                    <img src={require(`../../assets/images/frame-15.png`)}/>
                </div>
            </div>

            <div className='flex w-full bg-white drop-shadow-xl rounded-2xl mt-5'>
                <div className='flex w-[50%] h-[200px] bg-[#E0A234] rounded-l-2xl p-5 justify-center'>
                    <img src={require(`../../assets/images/frame-5.png`)}/>
                </div>

                <div className='flex w-[50%] justify-center items-center'>
                    <div className='h-fit px-3'>
                        <h3 className='text-[14px] text-center font-customBold mb-3'>{t('lender.title1.title3')}</h3>
                        <p className='text-[12px] text-center font-customRegular'>{t('lender.title1.text3')}</p>
                    </div>
                </div>
            </div>

            <div className='flex w-full bg-white drop-shadow-xl rounded-2xl mt-5'>
                <div className='flex w-[50%] justify-center items-center'>
                    <div className='h-fit px-3'>
                        <h3 className='text-[14px] text-center font-customBold mb-3'>{t('lender.title1.title4')}</h3>
                        <p className='text-[12px] text-center font-customRegular'>{t('lender.title1.text4')}</p>
                    </div>
                </div>

                <div className='flex w-[50%] h-[200px] bg-[#E0A234] rounded-r-2xl p-5 justify-center'>
                    <img src={require(`../../assets/images/frame-12.png`)}/>
                </div>
            </div>
        </div>

        <div className='hidden lg:flex justify-center w-[100%] bg-[#f6f6f6] px-5 lg:px-16 pb-10'>
            <div className='w-[1366px]'>
            <h3 className='pt-10 mb-5 text-[#000] text-[30px] font-customExtraBold text-center'>{t('lender.title2')}</h3>

            <div className='flex w-[100%] mt-10'>
                <div className='flex w-[25%] h-fit justify-end'>
                    <div className='grid justify-items-center'>
                        <img src={require(`../../assets/images/frame-32.png`)} className='w-[100%] h-fit'/>

                        <h3 className='text-[14px] text-center font-customBold mt-3 mb-3'>{t('lender.title2.title1')}</h3>
                        <p className='text-[12px] text-center font-customRegular'>{t('lender.title3.title1.text')}</p>
                    </div>
                </div>

                <div className='flex w-[15%] h-fit justify-center'>
                    <img src={require(`../../assets/images/arrow-1.png`)} className='w-[60%] h-fit'/>
                </div> 

                <div className='flex w-[25%] h-fit justify-center'>
                    <div className='grid justify-items-center'>
                        <img src={require(`../../assets/images/frame-18.png`)} className='w-[80%] h-fit'/>
                        
                        <h3 className='text-[14px] text-center font-customBold mt-3 mb-3'>{t('lender.title2.title2')}</h3>
                        <p className='text-[12px] text-center font-customRegular'>{t('lender.title2.title2.text')}</p>
                    </div>
                </div>

                <div className='flex w-[15%] h-fit justify-center'>
                    <img src={require(`../../assets/images/arrow-2.png`)} className='w-[60%] h-fit'/>
                </div>

                <div className='flex w-[25%] h-fit justify-start'>
                    <div className='grid justify-items-center'>
                        <img src={require(`../../assets/images/frame-33.png`)} className='w-[90%] h-fit'/>

                        <h3 className='text-[14px] text-center font-customBold mt-3 mb-3'>{t('lender.title2.title3')}</h3>
                        <p className='text-[12px] text-center font-customRegular'>{t('lender.title2.title2.text')}</p>
                    </div>
                </div>
            </div>
            </div>
        </div>

        {/* Mobile */}
        <div className='block lg:hidden w-[100%] h-fit bg-[#f6f6f6] px-5 pb-10'>
            <h3 className='pt-10 mb-5 text-[#000] text-[30px] font-customExtraBold text-center'>{t('lender.title2')}</h3>
            
            <div className='flex w-full items-center mt-10'>
                <img src={require(`../../assets/images/frame-32.png`)} className='w-[40%]'/>
                
                <div className='w-[60%] h-fit'>
                    <h3 className='text-[14px] text-center font-customBold mt-3 mb-3'>{t('lender.title2.title1')}</h3>
                    <p className='text-[12px] text-center font-customRegular'>{t('lender.title3.title1.text')}</p>
                </div>
            </div>

            <div className='flex w-full justify-center'>
                <img src={require(`../../assets/images/arrow-mobile1.png`)} className='w-[35%] h-fit'/>
            </div>

            <div className='flex w-full items-center'>
                <img src={require(`../../assets/images/frame-18.png`)} className='w-[40%]'/>
                
                <div className='w-[60%] h-fit'>
                    <h3 className='text-[14px] text-center font-customBold mt-3 mb-3'>{t('lender.title2.title2')}</h3>
                    <p className='text-[12px] text-center font-customRegular'>{t('lender.title2.title2.text')}</p>
                </div>
            </div>

            <div className='flex w-full justify-center'>
                <img src={require(`../../assets/images/arrow-mobile2.png`)} className='w-[35%] h-fit'/>
            </div>

            <div className='flex w-full items-center'>
                <img src={require(`../../assets/images/frame-33.png`)} className='w-[40%]'/>
                
                <div className='w-[60%] h-fit'>
                    <h3 className='text-[14px] text-center font-customBold mt-3 mb-3'>{t('lender.title2.title3')}</h3>
                    <p className='text-[12px] text-center font-customRegular'>{t('lender.title2.title2.text')}</p>
                </div>
            </div>
        </div>

        <div className='hidden sm:flex justify-center w-[100%] h-fit bg-white px-5 lg:px-16'>
            <div className='w-[100%] pc-3:w-[1366px]'>
                <h3 className='pt-10 mb-5 text-[#000] text-[30px] font-customExtraBold text-center'>{t('lender.title3')}</h3>
                
                <div className='flex mt-10'>
                    <div className='grid justify-items-center h-[400px]'>
                        <div className='flex items-center justify-center'>
                            <img src={require(`../../assets/images/frame-34.png`)}
                            className='w-[60%] h-fit'/>
                        </div>
                            <div className='grid w-[70%] px-5'>
                            <h3 className='text-[14px] text-center font-customBold mt-3 mb-3'>{t('lender.title3.title1')}</h3>
                            <p className='text-[12px] text-center font-customRegular'>{t('lender.title3.title1.text')}</p>
                        </div>
                    </div>

                    <div className='grid justify-items-center w-[400px]'>
                        <div className='flex items-center justify-center'>
                            <img src={require(`../../assets/images/frame-35.png`)}
                            className='w-[60%] h-fit'/>
                        </div>
                        <div className='grid w-[70%] px-5'>
                            <h3 className='text-[14px] text-center font-customBold mt-3 mb-3'>{t('lender.title3.title2')}</h3>
                            <p className='text-[12px] text-center font-customRegular'>{t('lender.title3.title2.text')}</p>
                        </div>
                    </div>

                    <div className='grid justify-items-center h-[400px]'>
                        <div className='flex items-center justify-center'>
                            <img src={require(`../../assets/images/frame-36.png`)}
                            className='w-[60%] h-fit'/>
                        </div>

                        <div className='grid w-[70%] px-5'>
                            <h3 className='text-[14px] text-center font-customBold mt-3 mb-3'>{t('lender.title3.title3')}</h3>
                            <p className='text-[12px] text-center font-customRegular'>{t('lender.title3.title2.text')}</p>
                        </div>
                    </div>
                </div>

                <CustomLink to = '/lender-app' className='flex w-[100%] bg-colorOrangeDark hover:bg-[#ff9346] hover:opacity-90 active:bg-[#ffae74] text-white font-customBold text-[14px] py-3 rounded-md items-center justify-center my-10'>{t('lender.button')} &nbsp;<FaArrowRight/></CustomLink>
            </div>

            {/* <div className='flex pb-10'>
                <div className='grid w-[40%] px-5'>
                    <h3 className='text-[14px] text-center font-customBold mt-3 mb-3'>{t('lender.title3.title1')}</h3>
                    <p className='text-[12px] text-center font-customRegular'>{t('lender.title3.title1.text')}</p>
                </div>

                <div className='grid w-[40%] px-5'>
                    <h3 className='text-[14px] text-center font-customBold mt-3 mb-3'>{t('lender.title3.title2')}</h3>
                    <p className='text-[12px] text-center font-customRegular'>{t('lender.title3.title2.text')}</p>
                </div>

                <div className='grid w-[40%] px-5'>
                    <h3 className='text-[14px] text-center font-customBold mt-3 mb-3'>{t('lender.title3.title3')}</h3>
                    <p className='text-[12px] text-center font-customRegular'>{t('lender.title3.title2.text')}</p>
                </div>
            </div> */}

            {/* <CustomLink to = '/lender-app' className='flex w-[100%] bg-colorOrangeDark hover:bg-[#ff9346] hover:opacity-90 active:bg-[#ffae74] text-white font-customBold text-[14px] py-3 rounded-md items-center justify-center my-10'>{t('lender.button')} &nbsp;<FaArrowRight/></CustomLink> */}
        </div>

        {/* Mobile */}
        <div className='block lg:hidden w-[100%] h-fit bg-white px-5 pb-10'>
            <h3 className='pt-10 mb-5 text-[#000] text-[30px] font-customExtraBold text-center'>{t('lender.title3')}</h3>

            <div className='h-[100%]'>
                <div className='grid justify-items-center'>
                    <img src={require(`../../assets/images/frame-34.png`)}
                    className='w-[375px]'/>
                    
                    <h3 className='text-[14px] text-center font-customBold mt-3 mb-3'>{t('lender.title3.title1')}</h3>
                    <p className='text-[12px] text-center font-customRegular'>{t('lender.title3.title1.text')}</p>
                </div>

                <div className='grid justify-items-center mt-10'>
                    <img src={require(`../../assets/images/frame-35.png`)}
                    className='w-[225px] h-fit'/>
                    
                    <h3 className='text-[14px] text-center font-customBold mt-3 mb-3'>{t('lender.title3.title2')}</h3>
                    <p className='text-[12px] text-center font-customRegular'>{t('lender.title3.title2.text')}</p>
                </div>

                <div className='grid justify-items-center mt-10'>
                    <img src={require(`../../assets/images/frame-36.png`)}
                    className='w-[375px] h-fit'/>
                    
                    <h3 className='text-[14px] text-center font-customBold mt-3 mb-3'>{t('lender.title3.title3')}</h3>
                    <p className='text-[12px] text-center font-customRegular'>{t('lender.title3.title2.text')}</p>
                </div>

                <CustomLink to = '/lender-app' className='flex w-[100%] bg-colorOrangeDark hover:bg-[#ff9346] hover:opacity-90 active:bg-[#ffae74] text-white font-customBold text-[14px] py-3 rounded-md items-center justify-center mt-10'>{t('lender.button')} &nbsp;<FaArrowRight/></CustomLink>
            </div>
        </div>

        {/* <div className='w-[100%] h-fit bg-grad pt-10 bg-[#f6f6f6]'>
            <h3 className='mb-5 text-[#000] text-center text-[30px] font-customExtraBold'>{t('sixth.home')}</h3>
            <div className='slider'>
                <div className='slide-track'>
                    <div className='slide max-w-fit mx-5 object-cover'>
                        <img src={require('../../assets/images/bca.png')}/>
                    </div>
                    <div className='slide max-w-fit mx-5 object-cover'>
                        <img src={require('../../assets/images/mandiri.png')}/>
                    </div>
                    <div className='slide max-w-fit mx-5 object-cover'>
                        <img src={require('../../assets/images/bni.png')}/>
                    </div>
                    <div className='slide max-w-fit mx-5 object-cover'>
                        <img src={require('../../assets/images/bri.png')}/>
                    </div>
                    <div className='slide max-w-fit mx-5 object-cover'>
                        <img src={require('../../assets/images/cimb.png')}/>
                    </div>
                    <div className='slide max-w-fit mx-5 object-cover'>
                        <img src={require('../../assets/images/permata.png')}/>
                    </div>
                    <div className='slide max-w-fit mx-5 object-cover'>
                        <img src={require('../../assets/images/alfamart.png')}/>
                    </div>
                    <div className='slide max-w-fit mx-5 object-cover'>
                        <img src={require('../../assets/images/indomaret.png')}/>
                    </div>
                    <div className='slide max-w-fit mx-5 object-cover'>
                        <img src={require('../../assets/images/doku.png')}/>
                    </div>
                    <div className='slide max-w-fit mx-5 object-cover'>
                        <img src={require('../../assets/images/instamoney.png')}/>
                    </div>

                    <div className='slide max-w-fit mx-5 object-cover'>
                        <img src={require('../../assets/images/bca.png')}/>
                    </div>
                    <div className='slide max-w-fit mx-5 object-cover'>
                        <img src={require('../../assets/images/mandiri.png')}/>
                    </div>
                    <div className='slide max-w-fit mx-5 object-cover'>
                        <img src={require('../../assets/images/bni.png')}/>
                    </div>
                    <div className='slide max-w-fit mx-5 object-cover'>
                        <img src={require('../../assets/images/bri.png')}/>
                    </div>
                    <div className='slide max-w-fit mx-5 object-cover'>
                        <img src={require('../../assets/images/cimb.png')}/>
                    </div>
                    <div className='slide max-w-fit mx-5 object-cover'>
                        <img src={require('../../assets/images/permata.png')}/>
                    </div>
                    <div className='slide max-w-fit mx-5 object-cover'>
                        <img src={require('../../assets/images/alfamart.png')}/>
                    </div>
                    <div className='slide max-w-fit mx-5 object-cover'>
                        <img src={require('../../assets/images/indomaret.png')}/>
                    </div>
                    <div className='slide max-w-fit mx-5 object-cover'>
                        <img src={require('../../assets/images/doku.png')}/>
                    </div>
                    <div className='slide max-w-fit mx-5 object-cover'>
                        <img src={require('../../assets/images/instamoney.png')}/>
                    </div>

                    <div className='slide max-w-fit mx-5 object-cover'>
                        <img src={require('../../assets/images/bca.png')}/>
                    </div>
                    <div className='slide max-w-fit mx-5 object-cover'>
                        <img src={require('../../assets/images/mandiri.png')}/>
                    </div>
                    <div className='slide max-w-fit mx-5 object-cover'>
                        <img src={require('../../assets/images/bni.png')}/>
                    </div>
                    <div className='slide max-w-fit mx-5 object-cover'>
                        <img src={require('../../assets/images/bri.png')}/>
                    </div>
                    <div className='slide max-w-fit mx-5 object-cover'>
                        <img src={require('../../assets/images/cimb.png')}/>
                    </div>
                    <div className='slide max-w-fit mx-5 object-cover'>
                        <img src={require('../../assets/images/permata.png')}/>
                    </div>
                    <div className='slide max-w-fit mx-5 object-cover'>
                        <img src={require('../../assets/images/alfamart.png')}/>
                    </div>
                    <div className='slide max-w-fit mx-5 object-cover'>
                        <img src={require('../../assets/images/indomaret.png')}/>
                    </div>
                    <div className='slide max-w-fit mx-5 object-cover'>
                        <img src={require('../../assets/images/doku.png')}/>
                    </div>
                    <div className='slide max-w-fit mx-5 object-cover'>
                        <img src={require('../../assets/images/instamoney.png')}/>
                    </div>
                </div>
            </div>
        </div> */}

        <div className='bg-[#f6f6f6]'>
            <h3 className='mb-5 text-[#000] text-center text-[30px] font-customExtraBold pt-10'>{t('sixth.home')}</h3>
            <div className='w-[100%] flex justify-center pb-10'>
                <div className="w-[100%] pc-3:w-[1366px] inline-flex flex-nowrap overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-200px),transparent_100%)]">
                    <ul class="flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll">
                        <li>
                            <img src={require('../../assets/images/bca.png')} alt="bca" />
                        </li>
                        <li>
                            <img src={require('../../assets/images/mandiri.png')} alt="Mandiri" />
                        </li>
                        <li>
                            <img src={require('../../assets/images/bni.png')} alt="bni" />
                        </li>
                        <li>
                            <img src={require('../../assets/images/bri.png')} alt="bri" />
                        </li>
                        <li>
                            <img src={require('../../assets/images/cimb.png')} alt="cimb" />
                        </li>
                        <li>
                            <img src={require('../../assets/images/permata.png')} alt="permata" />
                        </li>
                        <li>
                            <img src={require('../../assets/images/alfamart.png')} alt="alfamart" />
                        </li>
                        <li>
                            <img src={require('../../assets/images/indomaret.png')} alt="indomaret" />
                        </li>
                        <li>
                            <img src={require('../../assets/images/doku.png')} alt="doku" />
                        </li>
                        <li>
                            <img src={require('../../assets/images/instamoney.png')} alt="instamoney" />
                        </li>
                    </ul>
                    <ul class="flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll" aria-hidden="true">
                        <li>
                            <img src={require('../../assets/images/bca.png')} alt="bca" />
                        </li>
                        <li>
                            <img src={require('../../assets/images/mandiri.png')} alt="Mandiri" />
                        </li>
                        <li>
                            <img src={require('../../assets/images/bni.png')} alt="bni" />
                        </li>
                        <li>
                            <img src={require('../../assets/images/bri.png')} alt="bri" />
                        </li>
                        <li>
                            <img src={require('../../assets/images/cimb.png')} alt="cimb" />
                        </li>
                        <li>
                            <img src={require('../../assets/images/permata.png')} alt="permata" />
                        </li>
                        <li>
                            <img src={require('../../assets/images/alfamart.png')} alt="alfamart" />
                        </li>
                        <li>
                            <img src={require('../../assets/images/indomaret.png')} alt="indomaret" />
                        </li>
                        <li>
                            <img src={require('../../assets/images/doku.png')} alt="doku" />
                        </li>
                        <li>
                            <img src={require('../../assets/images/instamoney.png')} alt="instamoney" />
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        </>
    )
}

function CustomLink({ to, children, ...props }){
    const resolvePath = useResolvedPath(to)
    const isActive = useMatch({path: resolvePath.pathname, end: true})
    return(
        <li className = {`${isActive ? 'active' : ''} list-none`}>
            <Link to = {to} {...props}>{children}</Link>
        </li>
    )
}