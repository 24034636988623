import React, {useContext, useRef, useState} from "react"
import { multiStepContext } from "../Step-Context"
import { BsFillExclamationCircleFill } from 'react-icons/bs'
import { BiChevronDown } from 'react-icons/bi'
import { useTranslation } from 'react-i18next'
import { AiOutlineSearch } from 'react-icons/ai'
import validator from 'validator'
import '../Application.css'
import city from './city.json'
import province from './province.json'

export default()=>{
    const {setStep, userData, setUserData} = useContext(multiStepContext)
    const {t,i18n} = useTranslation()

    const institutionType = [
        {
            typeId: 0,
            typeName: "PT",

        },{
            typeId: 1,
            typeName: "CV",
        },{
            typeId: 2,
            typeName: "Others",
        }
    ]
    const currentYear = new Date().getFullYear()
    let year = []
         for(let yearCount = currentYear; yearCount >= 1900; yearCount--)
         year.push((<option value={yearCount} key={yearCount}>{yearCount}</option>))
    const [openYear, setOpenYear] = useState(false)
    const [openType, setOpenType] = useState(false)
    const [openCity, setOpenCity] = useState(false)
    const [openProvince, setOpenProvince] = useState(false)
    const [cityInputValue, setCityInputValue] = useState("")
    const [provinceInputValue, setProvinceInputValue] = useState("")

    const [errorInstitutionName, setErrorInstitutionName] = useState(false)
    const [errorInstitutionType, setErrorInstitutionType] = useState(false)
    const [errorBusinessType, setErrorBusinessType] = useState(false)
    const [errorEstablishmentTime, setErrorEstablishmentTime] = useState(false)
    const [errorEmail, setErrorEmail] = useState(false)
    const [errorPhone, setErrorPhone] = useState(false)
    const [errorPhoneLength, setErrorPhoneLength] = useState(false)
    const [errorHomeAddress, setErrorHomeAddress] = useState(false)
    const [errorInstitutionDesc, setErrorInstitutionDesc] = useState(false)
    const [errorCity, setErrorCity] = useState(false)
    const [errorProvince, setErrorProvince] = useState(false)
    const [errorPostalCode, setErrorPostalCode] = useState(false)
    
    const onSubmit=()=>{
        if(userData['institutionName'] == '' || userData['institutionName'] == null){
            setErrorInstitutionName(true)
        }else if(userData['typeId'] == null){
            setErrorInstitutionType(true)
        }else if(userData['BusinessType'] == '' || userData['BusinessType'] == null){
            setErrorBusinessType(true)
        }else if(userData['establishmentTime'] == null){
            setErrorEstablishmentTime(true)
        }else if(userData['institutionEmail'] == null || userData['institutionEmail'] == '' || !validator.isEmail(userData['institutionEmail'])){
            setErrorEmail(true)
        }else if(userData['institutionPhoneNumber'] == null || userData['institutionPhoneNumber'] == '' || !validator.isMobilePhone(userData['institutionPhoneNumber'])){
            setErrorPhone(true)
        }else if(userData['institutionPhoneNumber'].toString().length < 9 || userData['institutionPhoneNumber'].toString().length > 13){
            setErrorPhoneLength(true)
        }else if(userData['institutionAddress'] == null || userData['institutionAddress'] == ''){
            setErrorHomeAddress(true)
        }else if(userData['cityId'] == null){
            setErrorCity(true)
        }else if(userData['provinceId'] == null){
            setErrorProvince(true)
        }else if(userData['postalCode'] == '' || userData['postalCode'] == null){
            setErrorPostalCode(true)
        }else if(userData['institutionDesc'] == null || userData['institutionDesc'] == ''){
            setErrorInstitutionDesc(true)
        }else{
            setStep(4)
        }
    }

    const typeMenu = useRef(null)
    const closeTypeMenu = (e)=>{
        if(typeMenu.current && openType && !typeMenu.current.contains(e.target)){
        setOpenType(false)
        }
    }
    document.addEventListener('mousedown',closeTypeMenu)

    const establishmentMenu = useRef(null)
    const closeEstablishmentMenu = (e)=>{
        if(establishmentMenu.current && openYear && !establishmentMenu.current.contains(e.target)){
        setOpenYear(false)
        }
    }
    document.addEventListener('mousedown',closeEstablishmentMenu)

    const cityMenu = useRef(null)
    const closeCityMenu = (e)=>{
        if(cityMenu.current && openCity && !cityMenu.current.contains(e.target)){
            setOpenCity(false)
        }
    }
    document.addEventListener('mousedown',closeCityMenu)

    const provinceMenu = useRef(null)
    const closeProvinceMenu = (e)=>{
        if(provinceMenu.current && openProvince && !provinceMenu.current.contains(e.target)){
            setOpenProvince(false)
        }
    }
    document.addEventListener('mousedown',closeProvinceMenu)

    console.log(userData)
    return(
        <>
        <div className="flex w-[100%] h-fit p-5 bg-gray-200 mt-5">
            <BsFillExclamationCircleFill className="text-[32px] text-gray-900"/>
            <span className='text-[14px] font-customRegular text-gray-900 ml-5'>{t('loan.agreement')}</span>
        </div>
        
        <div className="grid lg:flex w-[100%] gap-5 mt-5">
        {/* Institution name */}
        <div className="w-[100%] lg:w-[50%]">
            <input 
            value={userData['institutionName']}
            onChange={(e)=>setUserData({...userData, "institutionName": e.target.value},
            setErrorInstitutionName(false))}
            className={`appearance-none block w-[100%] text-[14px] font-customRegular h-10 bg-white text-gray-900 border rounded py-2 px-2 leading-tight focus:outline-none focus:bg-white
            ${errorInstitutionName ? 'border-red-500' : 'border'}`} type="text" placeholder={t('loan.lender-type-corporate-institution-name')}/>
            <p className={`font-customRegular text-[12px] mt-1 text-red-500 ${errorInstitutionName ? 'block' : 'hidden'}`}>{t('lender.error.institution.name')}</p>
        </div>

            {/* Institution type */}
            <div className="w-[100%] lg:w-[50%]" ref={typeMenu}>
                <div onClick={()=>{
                    setOpenType(!openType)
                }}
                className={`w-[100%] p-2 bg-white text-[14px] font-customRegular border flex items-center justify-between cursor-pointer rounded ${
                    userData['typeName'] == null ? 'text-gray-400' : 'text-gray-900'}
                    ${errorInstitutionType ? 'border-red-500' : 'border'}`}>
                    {userData['typeName'] ? userData['typeName'] : `${t('loan.lender-type-corporate-institution-type')}`}
                    <BiChevronDown className={`text-gray-900 ${openType && 'rotate-180'}`}/>
                </div>

                <ul className={`w-[90%] lg:w-[35%] bg-white text-[14px] font-customRegular mt-2 shadow-md overflow-y-auto ${openType ? 'absolute max-h-60 border' : 'max-h-0'}`}>
                    {
                        institutionType.map((items)=>{
                            return(
                                <li key={items.typeId}
                                onClick={()=>{
                                    setUserData({...userData, "typeId" : items.typeId, "typeName" : items.typeName})
                                    setOpenType(!openType)
                                    setErrorInstitutionType(false)
                                }}
                                className="p-2 hover:bg-gray-100 cursor-pointer text-[14px] font-customRegular">{items.typeName}</li>
                            )
                        })
                    }
                </ul>
                <p className={`font-customRegular text-[12px] text-red-500 ${errorInstitutionType ? 'block' : 'hidden'}`}>{t('lender.error.institution.type')}</p>
            </div>
        </div>

        <div className="grid lg:flex w-[100%] gap-5 mt-5">
        {/* Bussines type*/}
        <div className="w-[100%] lg:w-[50%]">
            <input 
            value={userData['BusinessType']}
            onChange={(e)=>setUserData({...userData, "BusinessType": e.target.value},
            setErrorBusinessType(false))}
            className={`appearance-none block w-[100%] h-10 text-[14px] font-customRegular bg-white text-gray-900 border rounded py-2 px-2 leading-tight focus:outline-none focus:bg-white
            ${errorBusinessType ? 'border-red-500' : 'border'}`} type="text" placeholder={t('loan.lender-type-corporate-business-type')}/>
            <p className={`font-customRegular mt-1 text-[12px] text-red-500 ${errorBusinessType ? 'block' : 'hidden'}`}>{t('lender.error.institution.business.type')}</p>
        </div>

            {/* Year */}
            <div className="w-[100%] lg:w-[50%]" ref={establishmentMenu}>
                <div onClick={()=>{
                    setOpenYear(!openYear)
                }}
                className={`w-[100%] p-2 bg-white text-[14px] font-customRegular border flex items-center justify-between cursor-pointer rounded ${
                    userData['establishmentTime'] == null ? 'text-gray-400' : 'text-gray-900'}
                    ${errorEstablishmentTime ? 'border-red-500' : 'border'}`}>
                    {userData['establishmentTime'] ? userData['establishmentTime'] : `${t('loan.lender-type-corporate-establishment')}`}
                    <BiChevronDown className={`text-gray-900 ${openYear && 'rotate-180'}`}/>
                </div>

                <ul className={`w-[90%] lg:w-[35%] bg-white text-[14px] font-customRegular mt-2 shadow-md overflow-y-auto ${openYear ? 'absolute max-h-60 border' : 'max-h-0'}`}>
                    {
                        year.map((items)=>{
                            return(
                                <li key={items}
                                onClick={()=>{
                                    setUserData({...userData, "establishmentTime" : items.key})
                                    setOpenYear(!openYear)
                                    setErrorEstablishmentTime(false)
                                }}
                                className="p-2 hover:bg-gray-100 cursor-pointer font-customRegular">{items}</li>
                            )
                        })
                    }
                </ul>
                <p className={`font-customRegular text-[12px] text-red-500 ${errorEstablishmentTime ? 'block' : 'hidden'}`}>{t('lender.error.institution.establishment.time')}</p>
            </div>
        </div>

        <div className="grid lg:flex w-[100%] mt-5 gap-5">
                <div className="w-[100%] lg:w-[50%]">
                <input 
                    value={userData['institutionEmail']}
                    onChange={(e)=>setUserData({...userData, "institutionEmail": e.target.value},
                    setErrorEmail(false))}
                    className={`appearance-none block w-[100%] text-[14px] font-customRegular bg-white text-gray-900 border rounded py-2 px-2 leading-tight focus:outline-none focus:bg-white
                    ${errorEmail ? 'border-red-500' : 'border'}`} type="text" placeholder="Email"/>
                    <p className={`font-customRegular text-[12px] mt-1 text-red-500 ${errorEmail ? 'block' : 'hidden'}`}>{t('lender.error.institution.email')}</p>
                </div>
                <div className="w-[100%] lg:w-[50%]">
                <input
                type="number"
                value={userData['institutionPhoneNumber']}
                onChange={(e)=>setUserData({...userData, "institutionPhoneNumber": e.target.value},
                setErrorPhone(false),
                setErrorPhoneLength(false))}
                className={`appearance-none block w-[100%] bg-white text-gray-900 border rounded py-2 px-2 leading-tight focus:outline-none focus:bg-white text-[14px] font-customRegular
                ${errorPhone || errorPhoneLength ? 'border-red-500' : 'border'}`} placeholder={t('loan.lender-type-individual-phone')}/>
                <p className={`font-customRegular text-[12px] mt-1 text-red-500 ${errorPhone || errorPhoneLength ? 'block' : 'hidden'}`}>{
                errorPhone ? t('lender.error.institution.phone') : t('lender.error.institution.phone.length')}</p>
                </div>
            </div>

            <div className="w-[100%] mt-5">
            <input
            value={userData['institutionAddress']}
            onChange={(e)=>setUserData({...userData, "institutionAddress": e.target.value},
            setErrorHomeAddress(false))}
            className={`appearance-none block w-full bg-white text-gray-900 border rounded py-2 px-2 leading-tight focus:outline-none focus:bg-white text-[14px] font-customRegular
            ${errorHomeAddress ? 'border-red-500' : 'border'}`} type="text" placeholder={t('loan.lender-type-corporate-institution-address')}/>
            <p className={`font-customRegular mt-1 text-[12px] text-red-500 ${errorHomeAddress ? 'block' : 'hidden'}`}>{t('lender.error.institution.address')}</p>
        </div>

        <div className="grid lg:flex w-[100%] mt-5 gap-0 lg:gap-5">
        {/* City */}
        <div className="w-[100%] lg:w-[40%]" ref={cityMenu}>
                <div onClick={()=>{
                    setOpenCity(!openCity)
                }}
                className={`w-[100%] p-2 bg-white text-[14px] font-customRegular border flex items-center justify-between cursor-pointer rounded ${
                    userData['cityName'] == null ? 'text-gray-400' : 'text-gray-900'}
                    ${errorCity ? 'border-red-500' : 'border'}`}>
                    {userData['cityName'] ? userData['cityName'] : `${t('loan.lender-type-individual-city')}`}
                    <BiChevronDown className={`text-gray-900 ${openCity && 'rotate-180'}`}/>
                </div>

                <ul className={`w-[90%] lg:w-[27%] bg-white text-[14px] font-customRegular mt-2 shadow-md overflow-y-auto ${openCity ? 'absolute max-h-60 border' : 'max-h-0'}`}>
                    <div className="flex items-center px-2 sticky top-0 bg-white">
                        <AiOutlineSearch className="text-gray-500 text-[16px]"/>
                        <input type="text"
                        placeholder={t('loan.lender-type-individual-city-name')}
                        value={cityInputValue}
                        onChange={(e)=>setCityInputValue(e.target.value.toLowerCase())}
                        className={`p-2 w-[100%] text-gray-500 text-[14px] font-customRegular outline-none`}/>
                    </div>
                    {
                        city.map((items)=>{
                            return(
                                <li key={items.cityId}
                                onClick={()=>{
                                    setUserData({...userData, "cityId" : items.cityId, "cityName" : items.cityName})
                                    setOpenCity(!openCity)
                                    setErrorCity(false)
                                }}
                                className={`p-2 hover:bg-gray-100 cursor-pointer text-[14px] font-customRegular
                                ${items?.cityName?.toLowerCase().includes(cityInputValue) ? 'block' : 'hidden'}`}>{items.cityName}</li>
                            )
                        })
                    }
                </ul>
                <p className={`font-customRegular text-[12px] text-red-500 ${errorCity ? 'block' : 'hidden'}`}>{t('lender.error.city')}</p>
            </div>

            {/* Province */}
        <div className="w-[100%] lg:w-[40%] mt-5 lg:mt-0" ref={provinceMenu}>
                <div onClick={()=>{
                    setOpenProvince(!openProvince)
                }}
                className={`w-[100%] p-2 bg-white text-[14px] font-customRegular border flex items-center justify-between cursor-pointer rounded ${
                    userData['provinceName'] == null ? 'text-gray-400' : 'text-gray-900'}
                    ${errorProvince ? 'border-red-500' : 'border'}`}>
                    {userData['provinceName'] ? userData['provinceName'] : `${t('loan.lender-type-individual-province')}`}
                    <BiChevronDown className={`text-gray-900 ${openProvince && 'rotate-180'}`}/>
                </div>

                <ul className={`w-[90%] lg:w-[27%] bg-white text-[14px] font-customRegular mt-2 shadow-md overflow-y-auto ${openProvince ? 'absolute max-h-60 border' : 'max-h-0'}`}>
                    <div className="flex items-center px-2 sticky top-0 bg-white">
                        <AiOutlineSearch className="text-gray-500 text-[16px]"/>
                        <input type="text"
                        placeholder={t('loan.lender-type-individual-province-name')}
                        value={provinceInputValue}
                        onChange={(e)=>setProvinceInputValue(e.target.value.toLowerCase())}
                        className={`p-2 w-[100%] text-gray-500 text-[14px] font-customRegular outline-none`}/>
                    </div>
                    {
                        province.map((items)=>{
                            return(
                                <li key={items.provinceId}
                                onClick={()=>{
                                    setUserData({...userData, "provinceId" : items.provinceId, "provinceName" : items.provinceName})
                                    setOpenProvince(!openProvince)
                                    setErrorProvince(false)
                                }}
                                className={`p-2 hover:bg-gray-100 cursor-pointer text-[14px] font-customRegular
                                ${items?.provinceName?.toLowerCase().includes(provinceInputValue) ? 'block' : 'hidden'}`}>{items.provinceName}</li>
                            )
                        })
                    }
                </ul>
                <p className={`font-customRegular text-[12px] text-red-500 ${errorProvince ? 'block' : 'hidden'}`}>{t('lender.error.province')}</p>
            </div>

            <div className="w-[100%] lg:w-[20%]">
                <input
                maxLength={5}
                value={userData['postalCode']}
                onChange={(e)=>setUserData({...userData, "postalCode": e.target.value},
                setErrorPostalCode(false))}
                className={`appearance-none text-[14px] font-customRegular block w-[100%] h-[40px] bg-white text-gray-900 border rounded py-2 px-2 leading-tight focus:outline-none focus:bg-white mt-5 lg:mt-0 ${errorPostalCode ? 'border-red-500' : 'border'}`} type="number" placeholder={t('loan.lender-type-individual-postal-code')}/>
                <p className={`font-customRegular text-[12px] mt-1 text-red-500 ${errorPostalCode ? 'block' : 'hidden'}`}>{t('lender.error.postal.code')}</p>
            </div>
        </div>

        <div className="w-[100%] mt-5">
            <textarea 
            value={userData['institutionDesc']}
            onChange={(e)=>setUserData({...userData, "institutionDesc": e.target.value, "institutionDocumentType" : 0},
            setErrorInstitutionDesc(false))}
            className={`appearance-none block w-full text-[14px] font-customRegular bg-white text-gray-900 border rounded py-2 px-2 leading-tight focus:outline-none focus:bg-white resize-none
            ${errorInstitutionDesc ? 'border-red-500' : 'border'}`} type="text" placeholder={t('loan.lender-type-corporate-institution-desc')}/>
            <p className={`font-customRegular text-[12px] mt-1 text-red-500 ${errorInstitutionDesc ? 'block' : 'hidden'}`}>{t('lender.error.institution.desc')}</p>
        </div>

        <div className="flex w-[100%] my-5 gap-5">
                <button class="w-[50%] bg-gray-500 border text-white font-customBold py-2 px-4 rounded shadow-md">
                    <div className="flex items-center justify-center"
                    onClick={()=>(
                        setStep(1)
                    )}>
                        {t('loan.back')}
                    </div>
                </button>

                <button class="w-[50%] bg-[#E77B30] border text-white font-customBold py-2 px-4 rounded shadow-md">
                    <div className="flex items-center justify-center"
                    onClick={()=>(
                        onSubmit()
                    )}>
                        {t('loan.next')}
                    </div>
                </button>
            </div>
        </>
    )
}