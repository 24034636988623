import React, { useState, useEffect } from 'react'
import Second from '../../home/second/Second'
import { useTranslation } from 'react-i18next'
import AOS from "aos"
import "aos/dist/aos.css"

export default() =>{
    const {t,i18n} = useTranslation()

    const [actived, setActived] = useState(1)
    const toggle = (index) =>{
        setActived(index)
    }

    const lowestItems = [{
            'title':`${t('forth.about.ktakilat.tab.text1')}`,
            'value':'Rp. 400,000'
        },
        {
            'title':`${t('forth.about.ktakilat.tab.text2')}`,
            'value':'Rp. 48,000'
        },
        {
            'title':`${t('forth.about.ktakilat.tab.text3')}`,
            'value':'Rp. 448,000'
        },
        {
            'title':`${t('forth.about.ktakilat.tab.text4')}`,
            'value':'0,40%'
        }
    ]

    const highestItems = [{
            'title':`${t('forth.about.ktakilat.tab.text1')}`,
            'value':'Rp. 4,500,000'
        },
        {
            'title':`${t('forth.about.ktakilat.tab.text2')}`,
            'value':'Rp. 4,941,700'
        },
        {
            'title':`${t('forth.about.ktakilat.tab.text3')}`,
            'value':'Rp. 441,700'
        },
        {
            'title':`${t('forth.about.ktakilat.tab.text4')}`,
            'value':'0,33%'
        }
    ]

    const thirdSectionItems = [{
            'url':'frame-5.png',
            'title':`${t('third.title.first')}`,
            'desc':`${t('third.text.first')}`
        },
        {
            'url':'frame-6.png',
            'title':`${t('third.title.second')}`,
            'desc':`${t('third.text.second')}`
        },
        {
            'url':'frame-7.png',
            'title':`${t('third.title.third')}`,
            'desc':`${t('third.text.third')}`
        },
        {
            'url':'frame-8.png',
            'title':`${t('third.title.forth')}`,
            'desc':`${t('third.text.forth')}`
        },
        {
            'url':'frame-9.png',
            'title':`${t('third.title.fifth')}`,
            'desc':`${t('third.text.fifth')}`
        },
        {
            'url':'frame-10.png',
            'title':`${t('third.title.sixth')}`,
            'desc':`${t('third.text.sixth')}`
        }
    ]
    return(
        <>
            {/* 1st section */}
            <div className='grid lg:flex w-[100%] pt-5 pb-10 lg:pb-0 px-5 xl:px-14 justify-between bg-gradient-to-b from-white via-gray-100 to-gray-200'>
                <div className='hidden lg:flex w-[50%] pr-10 items-center'>
                    <div>
                        <h3 className='mb-5 text-[#000] text-[30px] font-customExtraBold pt-5'>{t('first.about.ktakilat.title')}</h3>
                        <p className='text-[#000] text-[14px] font-customRegular'><span className='font-customBold'>KTA Kilat</span> {t('first.about.ktakilat.text1')}
                        <br/><br/>{t('first.about.ktakilat.text2')}
                        <br/><br/>{t('first.about.ktakilat.text3')}</p>
                    </div>
                </div>

                <div className='flex w-[100%] lg:w-[50%] items-center justify-center lg:justify-end py-10'>
                    <img src={require('../../../assets/images/frame-4.png')} className='w-[550px]'/>
                </div>

                <div className='block lg:hidden'>
                    <h3 className='mb-5 text-[#000] text-[30px] font-customExtraBold pt-5'>{t('first.about.ktakilat.title')}</h3>
                    <p className='text-[#000] text-[14px] font-customRegular'><span className='font-customBold'>KTA Kilat</span> {t('first.about.ktakilat.text1')}
                    <br/><br/>{t('first.about.ktakilat.text2')}
                    <br/><br/>{t('first.about.ktakilat.text3')}</p>
                </div>
            </div>
            <div className='bg-white'>
                <Second/>
            </div>
            <div className='grid justify-items-center bg-gradient-to-b from-[#f6f6f6] via-[#f6f6f6] to-[#e77b30] py-10'>
                <h3 className='mx-5 text-[#000] text-[30px] text-center font-customExtraBold'>{t('third.title')}</h3>
                <div className='flex mt-5 flex-wrap h-fit justify-center'>
                    {
                    thirdSectionItems.map((i)=>{
                        return(
                        <div style={{
                            backgroundColor: `#e77b30`
                            }} className='w-[42%] lg:w-[25%] xl:w-[14%] m-3 py-5 px-3 rounded-2xl transform transition duration-500 hover:scale-110'>
                                <div className='grid text-center text-white justify-items-center'>
                                    <img src={require(`../../../assets/images/${i.url}`)} className='h-[75px] object-cover mb-5'/>
                                    <span className='mb-3 text-[14px] font-customBold'>{i.title}</span>
                                    <p className='text-[12px] font-customLight'>{i.desc}</p>
                                </div>
                            </div>
                        )})}
                    </div>
                </div>

            <div className='grid justify-items-center bg-white'>
                <h3 className='text-[#000] text-[30px] font-customExtraBold pt-10 text-center'>{t('forth.about.ktakilat.title')}</h3>
                <div className='w-[100%] h-fit'>
                    <div className='flex justify-center pt-5 px-5 xl:px-10'>
                        <button className={`py-2 md:py-5 px-3 w-[50%] rounded-l-lg ${ actived === 1 ? 'tab active' : 'tab'}`}
                        onClick={() =>{
                            toggle(1)
                        }}>{t('forth.about.ktakilat.tab1')}</button>
                        <button className={`py-2 md:py-5 px-3 w-[50%] rounded-r-lg ${ actived === 2 ? 'tab active' : 'tab'}`}
                        onClick={() =>{
                            toggle(2)
                        }}>{t('forth.about.ktakilat.tab2')}</button>
                    </div>

                    {/* Content-1 */}
                    <div className={`px-5 lg:px-10 pb-10 ${ actived === 1 ? 'content active' : 'content'} font-customRegular`}>
                        <div className='grid lg:flex lg:flex-wrap lg:justify-center'>
                            {
                                lowestItems.map((i)=>{
                                    return(
                                        <div className='w-[100%] lg:w-[50%] h-[50px] mt-10'>
                                            <h3 className='text-[16px] font-customBold'>{i.title}</h3>
                                            <h1 className='text-[30px] text-colorOrangeDark font-customBold'>{i.value}</h1>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>

                    {/* Content-2 */}
                    <div className={`px-5 lg:px-10 pb-10 ${ actived === 2 ? 'content active' : 'content'} font-customRegular`}>
                        <div className='grid lg:flex lg:flex-wrap lg:justify-center'>
                            {
                                highestItems.map((i)=>{
                                    return(
                                        <div className='w-[100%] lg:w-[50%] h-[50px] mt-10'>
                                            <h3 className='text-[16px] font-customBold'>{i.title}</h3>
                                            <h1 className='text-[30px] text-colorOrangeDark font-customBold'>{i.value}</h1>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}