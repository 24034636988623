import React, { useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode } from 'swiper'
import SwiperCore, { Autoplay, Pagination } from 'swiper'
import { HiOutlineArrowLongLeft, HiOutlineArrowLongRight } from 'react-icons/hi2'
import 'swiper/css'
import './LifeAtPendanaan.css'
import Poster2 from '../footer/Poster-2'
import { useTranslation } from 'react-i18next'

export default() =>{
    const {t,i18n} = useTranslation()
    const swiperRef = useRef()
    const secondSwiperRef = useRef()
    SwiperCore.use([Autoplay, Pagination])

    const items = [{
            'url':'frame-11.png',
            'title':`${t('second.lap.card.title1')}`,
            'desc':`${t('second.lap.card.title1.text')}`,
            'color': '#E0A234',
            'ml': '16px'
        },
        {
            'url':'frame-12.png',
            'title':`${t('second.lap.card.title2')}`,
            'desc':`${t('second.lap.card.title2.text')}`,
            'color': '#E0A234',
            'ml': '0px'
        },
        {
            'url':'frame-13.png',
            'title':`${t('second.lap.card.title3')}`,
            'desc':`${t('second.lap.card.title3.text')}`,
            'color': '#E0A234',
            'ml': '0px'
        },
        {
            'url':'frame-14.png',
            'title':`${t('second.lap.card.title4')}`,
            'desc':`${t('second.lap.card.title4.text')}`,
            'color': '#E0A234',
            'ml': '0px'
        },
        {
            'url':'frame-15.png',
            'title':`${t('second.lap.card.title5')}`,
            'desc':`${t('second.lap.card.title5.text')}`,
            'color': '#E0A234',
            'ml': '0px'
        }
    ]

    const testi = [{
            'url':'refael.png',
            'name':'Refael Stivzer',
            'position':'Business Development Manager',
            'desc':<div>“One of my main responsibilities here is to establish a cooperation as well as maintaining relationship with third parties. Whenever I successfully forge a partnership with another company, that's the most fulfilling feelings.”</div>
        },
        {
            'url':'achmad.png',
            'name':'Amanda Anasthasya',
            'position':'CS & Telemarketing Manager',
            'desc':<div>“Working in Pendanaan has been incredibly rewarding, offering new opportunities to grow, innovate, and contribute to impactful projects.”</div>
        },
        {
            'url':'fanda.png',
            'name':'Elly Octaviana',
            'position':'Business Development Specialist',
            'desc':<div>“Transitioning from a completely different industry, this company has provided me with the opportunity to learn about FinTech from the ground up and understand its operational intricacies. The working environment is delightful, characterized by a team of supportive and high-spirited members. I find the most fulfillment when collaborating with other division members to overcome obstacles and achieve our project goals.”</div>
        }
    ]

    const pictures=[{
        'url':'picture-1.jpeg',
        'name':`${t('fifth.lap.title1')}`,
        'mr': '0px',
        'ml': '250px'
    },{
        'url':'picture-2.jpeg',
        'name':`${t('fifth.lap.title2')}`,
        'mr': '0px',
        'ml': '10px'
    },{
        'url':'picture-3.jpg',
        'name':`${t('fifth.lap.title3')}`,
        'mr': '0px',
        'ml': '10px'
    },{
        'url':'picture-4.jpg',
        'name':`${t('fifth.lap.title4')}`,
        'mr': '0px',
        'ml': '10px'
    },{
        'url':'picture-5.jpg',
        'name':`${t('fifth.lap.title5')}`,
        'mr': '0px',
        'ml': '10px'
    },{
        'url':'picture-6.jpeg',
        'name':`${t('fifth.lap.title6')}`,
        'mr': '0px',
        'ml': '10px'
    },{
        'url':'picture-7.jpg',
        'name':`${t('fifth.lap.title7')}`,
        'mr': '0px',
        'ml': '10px'
    },{
        'url':'picture-12.png',
        'name':`${t('fifth.lap.title8')}`,
        'mr': '0px',
        'ml': '10px'
    },{
        'url':'picture-8.jpeg',
        'name':`${t('fifth.lap.title9')}`,
        'mr': '0px',
        'ml': '10px'
    },{
        'url':'picture-9.png',
        'name':`${t('fifth.lap.title10')}`,
        'mr': '0px',
        'ml': '10px'
    },{
        'url':'picture-10.png',
        'name':`${t('fifth.lap.title11')}`,
        'mr': '0px',
        'ml': '10px'
    },{
        'url':'picture-11.png',
        'name':`${t('fifth.lap.title12')}`,
        'mr': '0px',
        'ml': '10px'
    },{
        'url':'BAC.jpg',
        'name':`ASEAN Business Advisory Council (BAC) Fintech Roundtable Luncheon`,
        'mr': '250px',
        'ml': '10px'
    }]

    const [isClick1, setIsClick1] = useState(true)
    const [isClick2, setIsClick2] = useState(false)
    const [isClick3, setIsClick3] = useState(false)
    return(
        <>
        {/* 1st section */}
        <div className='hidden sm:flex justify-center w-[100%] h-fit pt-32 pb-10 px-5 xl:px-14 bg-gradient-to-b from-[#ffffff] via-[#f6f6f6] to-[#E0A234]'>
            <div className='flex w-[100%] pc-3:w-[1366px] pl-10'>
                <div className='flex w-[30%] items-center'>
                    <div>
                        <h3 className='mb-5 text-[#000] text-[30px] font-customExtraBold pt-5'>{t('first.lap.title')}</h3>
                        <p className='text-[#000] text-[14px] font-customRegular'>{t('first.lap.text')}</p>
                    </div>
                </div>
                <div className='w-[10%]'/>
                <div className='flex w-[60%] justify-end'>
                    <div className='flex gap-4'>
                        <div className={`relative overflow-hidden w-[150px] h-[450px] rounded-2xl cursor-pointer ${isClick1 ? 'w-[350px]' : 'w-[100px]'} transition-all duration-1000 bg-color-custom-orange-dark`}
                        onClick={()=>{
                            setIsClick1(true)
                            setIsClick2(false)
                            setIsClick3(false)
                        }}
                        style={{ writingMode: 'vertical-rl' }}>
                            <div className={`absolute -bottom-10 h-[250px] z-10 ${isClick1 ? 'w-[350px]' : 'w-[150px]' } transition-all duration-1000 bg-gradient-to-t from-black to-transparent`}/>
                            <img src={require(`../../assets/images/refael.png`)} className={`absolute bottom-0 right-0 object-cover ${isClick1 ? 'h-[450px]' : 'h-[400px]'} transition-all duration-1000`}/>
                            <div className={`text-white absolute text-[23px] font-customExtraBold rotate-180 bottom-5 ${isClick1 ? '-left-[150px] opacity-0 -z-10' : 'left-1 opacity-100 z-10'} duration-1000`}>
                                Refael Stivzer
                            </div>
                            
                            <div className={`absolute w-[350px] h-fit bottom-0 p-5 z-20 ${isClick1 ? 'right-0 opacity-100 delay-150' : '-right-[350px] opacity-0'} transition-all duration-1000`}
                            style={{ writingMode: 'horizontal-tb'}}>
                                <p className='text-white text-[12px] font-customRegular'>“One of my main responsibilities here is to establish a cooperation as well as maintaining relationship with third parties. Whenever I successfully forge a partnership with another company, that's the most fulfilling feelings.”</p>
                                <span className='text-white text-[23px] font-customBold'>Refael Stivzer</span>
                                <p className='text-white text-[12px] font-customRegular'>Business Development Manager</p>
                            </div>
                        </div>

                        <div className={`relative overflow-hidden w-[150px] h-[450px] rounded-2xl cursor-pointer ${isClick2 ? 'w-[350px]' : 'w-[100px]'} transition-all duration-1000 bg-color-custom-orange-dark`}
                        onClick={()=>{
                            setIsClick1(false)
                            setIsClick2(true)
                            setIsClick3(false)
                        }}
                        style={{ writingMode: 'vertical-rl' }}>
                            <div className={`absolute -bottom-10 h-[250px] z-10 ${isClick2 ? 'w-[350px]' : 'w-[150px]' } transition-all duration-1000 bg-gradient-to-t from-black to-transparent`}/>
                            <img src={require(`../../assets/images/fanda.png`)} className={`absolute bottom-0 right-0 object-cover ${isClick2 ? 'h-[425px]' : 'h-[375px]'} transition-all duration-1000`}/>
                            <div className={`text-white absolute text-[23px] font-customExtraBold rotate-180 bottom-5 ${isClick2 ? '-left-[150px] opacity-0 -z-10' : 'left-1 opacity-100 z-10'} duration-1000`}>
                                Elly Octaviana
                            </div>
                            <div className={`absolute w-[350px] h-fit bottom-0 p-5 z-20 ${isClick2 ? 'right-0 opacity-100 delay-150' : '-right-[350px] opacity-0'} transition-all duration-1000`}
                            style={{ writingMode: 'horizontal-tb'}}>
                                <p className='text-[12px] text-white font-customRegular'>“Transitioning from a completely different industry, this company has provided me with the opportunity to learn about FinTech from the ground up and understand its operational intricacies. The working environment is delightful, characterized by a team of supportive and high-spirited members. I find the most fulfillment when collaborating with other division members to overcome obstacles and achieve our project goals.”</p>
                                <span className='text-[23px] text-white font-customBold'>Elly Octaviana</span>
                                <p className='text-[12px] text-white font-customRegular'>Business Development Specialist</p>
                            </div>
                        </div>

                        <div className={`relative overflow-hidden w-[150px] h-[450px] rounded-2xl cursor-pointer ${isClick3 ? 'w-[350px]' : 'w-[100px]'} transition-all duration-1000 bg-color-custom-orange-dark`}
                        onClick={()=>{
                            setIsClick1(false)
                            setIsClick2(false)
                            setIsClick3(true)
                        }}
                        style={{ writingMode: 'vertical-rl' }}>
                            <div className={`absolute -bottom-10 h-[250px] z-10 ${isClick3 ? 'w-[350px]' : 'w-[150px]' } transition-all duration-1000 bg-gradient-to-t from-black to-transparent`}/>
                            <img src={require(`../../assets/images/achmad.png`)} className={`absolute bottom-0 right-0 object-cover ${isClick3 ? 'h-[425px]' : 'h-[375px]'} transition-all duration-1000`}/>
                            <div className={`text-white absolute text-[23px] font-customExtraBold rotate-180 bottom-5 ${isClick3 ? '-left-[150px] opacity-0 -z-10' : 'left-1 opacity-100 z-10'} duration-1000`}>
                                Amanda Anasthasya
                            </div>
                            <div className={`absolute w-[350px] h-fit bottom-0 p-5 z-20 ${isClick3 ? 'right-0 opacity-100 delay-150' : '-right-[350px] opacity-0'} transition-all duration-1000`}
                            style={{ writingMode: 'horizontal-tb'}}>
                                <p className='text-[12px] text-white font-customRegular'>“Working in Pendanaan has been incredibly rewarding, offering new opportunities to grow, innovate, and contribute to impactful projects.”</p>
                                <span className='text-[23px] text-white font-customBold'>Amanda Anasthasya</span>
                                <p className='text-[12px] text-white font-customRegular'>CS & Telemarketing Manager</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>

            {/* Mobile */}
            <div className='block sm:hidden w-[100%] h-fit px-3 pt-24 pb-14 bg-gradient-to-b from-[#ffffff] via-[#f6f6f6] to-[#E0A234]'>
                <div className='flex w-[100%] justify-center'>
                    <div className='flex gap-[2px]'>
                        <div className={`relative overflow-hidden w-[250px] h-[450px] rounded-2xl cursor-pointer ${isClick1 ? 'w-[250px]' : 'w-[50px]'} transition-all duration-1000 bg-color-custom-orange-dark`}
                        onClick={()=>{
                            setIsClick1(true)
                            setIsClick2(false)
                            setIsClick3(false)
                        }}
                        style={{ writingMode: 'vertical-rl' }}>
                            <div className={`absolute -bottom-10 h-[250px] z-10 ${isClick1 ? 'w-[350px]' : 'w-[75px]' } transition-all duration-1000 bg-gradient-to-t from-black to-transparent`}/>
                            <img src={require(`../../assets/images/refael.png`)} className={`absolute bottom-0 right-0 object-cover ${isClick1 ? 'h-[450px]' : 'h-[400px]'} transition-all duration-1000`}/>
                            <div className={`text-white absolute text-[23px] font-customExtraBold rotate-180 bottom-5 ${isClick1 ? '-left-[150px] opacity-0 -z-10' : 'left-1 opacity-100 z-10'} duration-1000`}>
                                Refael Stivzer
                            </div>
                            
                            <div className={`absolute w-[250px] h-fit bottom-0 p-5 z-20 ${isClick1 ? 'right-0 opacity-100 delay-150' : '-right-[350px] opacity-0'} transition-all duration-1000`}
                            style={{ writingMode: 'horizontal-tb'}}>
                                <p className='text-white text-[12px] font-customRegular'>“One of my main responsibilities here is to establish a cooperation as well as maintaining relationship with third parties. Whenever I successfully forge a partnership with another company, that's the most fulfilling feelings.”</p>
                                <span className='text-white text-[23px] font-customBold'>Refael Stivzer</span>
                                <p className='text-white text-[12px] font-customRegular'>Business Development Manager</p>
                            </div>
                        </div>

                        <div className={`relative overflow-hidden w-[250px] h-[450px] rounded-2xl cursor-pointer ${isClick2 ? 'w-[250px]' : 'w-[50px]'} transition-all duration-1000 bg-color-custom-orange-dark`}
                        onClick={()=>{
                            setIsClick1(false)
                            setIsClick2(true)
                            setIsClick3(false)
                        }}
                        style={{ writingMode: 'vertical-rl' }}>
                            <div className={`absolute -bottom-10 h-[250px] z-10 ${isClick2 ? 'w-[350px]' : 'w-[150px]' } transition-all duration-1000 bg-gradient-to-t from-black to-transparent`}/>
                            <img src={require(`../../assets/images/fanda.png`)} className={`absolute bottom-0 right-0 object-cover ${isClick2 ? 'h-[425px]' : 'h-[375px]'} transition-all duration-1000`}/>
                            <div className={`text-white absolute text-[23px] font-customExtraBold rotate-180 bottom-5 ${isClick2 ? '-left-[150px] opacity-0 -z-10' : 'left-1 opacity-100 z-10'} duration-1000`}>
                                Elly Octaviana
                            </div>
                            <div className={`absolute w-[250px] h-fit bottom-0 p-5 z-20 ${isClick2 ? 'right-0 opacity-100 delay-150' : '-right-[350px] opacity-0'} transition-all duration-1000`}
                            style={{ writingMode: 'horizontal-tb'}}>
                                <p className='text-[12px] text-white font-customRegular'>“Transitioning from a completely different industry, this company has provided me with the opportunity to learn about FinTech from the ground up and understand its operational intricacies. The working environment is delightful, characterized by a team of supportive and high-spirited members. I find the most fulfillment when collaborating with other division members to overcome obstacles and achieve our project goals.”</p>
                                <span className='text-[23px] text-white font-customBold'>Elly Octaviana</span>
                                <p className='text-[12px] text-white font-customRegular'>Business Development Specialist</p>
                            </div>
                        </div>

                        <div className={`relative overflow-hidden w-[250px] h-[450px] rounded-2xl cursor-pointer ${isClick3 ? 'w-[250px]' : 'w-[50px]'} transition-all duration-1000 bg-color-custom-orange-dark`}
                        onClick={()=>{
                            setIsClick1(false)
                            setIsClick2(false)
                            setIsClick3(true)
                        }}
                        style={{ writingMode: 'vertical-rl' }}>
                            <div className={`absolute -bottom-10 h-[250px] z-10 ${isClick3 ? 'w-[350px]' : 'w-[150px]' } transition-all duration-1000 bg-gradient-to-t from-black to-transparent`}/>
                            <img src={require(`../../assets/images/achmad.png`)} className={`absolute bottom-0 right-0 object-cover ${isClick3 ? 'h-[425px]' : 'h-[375px]'} transition-all duration-1000`}/>
                            <div className={`text-white absolute text-[23px] font-customExtraBold rotate-180 bottom-5 ${isClick3 ? '-left-[150px] opacity-0 -z-10' : 'left-1 opacity-100 z-10'} duration-1000`}>
                                Amanda Anasthasya
                            </div>
                            <div className={`absolute w-[250px] h-fit bottom-0 p-5 z-20 ${isClick3 ? 'right-0 opacity-100 delay-150' : '-right-[350px] opacity-0'} transition-all duration-1000`}
                            style={{ writingMode: 'horizontal-tb'}}>
                                <p className='text-[12px] text-white font-customRegular'>“Working in Pendanaan has been incredibly rewarding, offering new opportunities to grow, innovate, and contribute to impactful projects.”</p>
                                <span className='text-[23px] text-white font-customBold'>Amanda Anasthasya</span>
                                <p className='text-[12px] text-white font-customRegular'>CS & Telemarketing Manager</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='flex w-[100%] items-center'>
                    <div>
                        <h3 className='mb-5 text-[#000] text-[30px] font-customExtraBold pt-5'>{t('first.lap.title')}</h3>
                        <p className='text-[#000] text-[14px] font-customRegular'>{t('first.lap.text')}</p>
                    </div>
                </div>
            </div>

            {/* 2nd section */}
            <div className='flex w-[100%] h-fit bg-white pt-10 justify-center'>
                <div className='w-[100%] pc-3:w-[1366px]'>
                <h3 className='mb-5 text-[#000] text-[30px] font-customExtraBold text-center'>{t('second.lap.title')}</h3>

                <div className='flex w-[100%] lg:px-10 mt-5 lg:mt-0 md:pl-0 py-5'>
                <Swiper
                spaceBetween={0}
                slidesPerView={1.6}
                freeMode={true}
                modules={[FreeMode]}
                breakpoints={{
                    375:{
                        slidesPerView:1.5,
                        spaceBetween:0
                    },
                    412:{
                        slidesPerView:1.7,
                        spaceBetween:0
                    },
                    540:{
                        slidesPerView:2.3,
                        spaceBetween:0
                    },
                    640: {
                        slidesPerView:2.3,
                    },
                    768: {
                        slidesPerView:3.3,
                    },
                    820: {
                        slidesPerView:3.7,
                    },
                    912: {
                        slidesPerView:3.7,
                    },
                    1024: {
                        slidesPerView:4.2,
                    },
                    1280: {
                        slidesPerView:5,
                    }
                }}
                className='w-fit'>
                {
                    items.map((i)=>{
                        return(
                            <SwiperSlide>
                            <div style={{
                                backgroundColor: `${i.color}`
                            }}
                            className={`ml-3 sm:ml-0 min-h-[350px] rounded-2xl p-5 ml-[${i.ml}] mr-[16px]`}>
                                <div className='flex justify-center pb-10'>
                                    <img src={require(`../../assets/images/${i.url}`)}
                                    className='h-[125px]'/>
                                </div>
                                <h3 className='text-[14px] text-center text-white font-customBold mb-3'>{i.title}</h3>
                                <p className='text-[12px] text-center text-white font-customRegular'>{i.desc}</p>
                            </div>
                            </SwiperSlide>
                        )
                    })
                }
                </Swiper>
            </div>
            </div>
            </div>

            {/* 3rd section */}
            <div className='grid sm:flex w-[100%] h-fit bg-white py-10 px-4 lg:px-14 justify-center'>
                <div className='w-[100%] pc-3:w-[1366px]'>
                    <h3 className='mb-5 text-[#000] text-[30px] font-customExtraBold text-center'>{t('third.lap.title')}</h3>
                    <div className='grid sm:flex gap-5 justify-center'>
                        <div className='w-[100%] sm:w-[35%] p-5 rounded-2xl bg-color-custom-orange-dark'>
                            <h3 className='text-[23px] text-center text-white font-customBold mb-5'>
                                {t('third.lap.title1')}
                            </h3>

                            <p className='text-[12px] text-white text-center font-customRegular'>
                                {t('third.lap.title1.text')}
                            </p>
                        </div>
                        <div className='w-[100%] sm:w-[35%] p-5 rounded-2xl bg-color-custom-orange-dark'>
                            <h3 className='text-[23px]  text-center text-white font-customBold mb-5'>
                                {t('third.lap.title2')}
                            </h3>

                            <p className='text-[12px] text-white text-center font-customRegular'>
                                {t('third.lap.title2.text')}
                            </p>
                        </div>
                        <div className='w-[100%] sm:w-[35%] p-5 rounded-2xl bg-color-custom-orange-dark'>
                            <h3 className='text-[23px] text-center text-white font-customBold mb-5'>
                                {t('third.lap.title3')}
                            </h3>

                            <p className='text-[12px] text-white text-center font-customRegular'>
                                {t('third.lap.title3.text')}
                            </p>
                        </div>
                    </div>

                    <div className='hidden sm:flex gap-5 justify-center mt-5'>
                        <div className='w-[100%] sm:w-[50%] p-5 rounded-2xl bg-color-custom-orange-dark'>
                            <h3 className='text-[23px] text-center text-white font-customBold mb-5'>
                                {t('third.lap.title4')}
                            </h3>

                            <p className='text-[12px] text-white text-center font-customRegular'>
                                {t('third.lap.title4.text')}
                            </p>
                        </div>
                        <div className='w-[100%] sm:w-[50%] p-5 rounded-2xl bg-color-custom-orange-dark'>
                            <h3 className='text-[23px] text-center text-white font-customBold mb-5'>
                                {t('third.lap.title5')}
                            </h3>

                            <p className='text-[12px] text-white text-center font-customRegular'>
                                {t('third.lap.title5.text')}
                            </p>
                        </div>
                    </div>

                    <div className='block sm:hidden justify-center mt-5'>
                        <div className='w-[100%] sm:w-[50%] p-5 rounded-2xl bg-color-custom-orange-dark'>
                            <h3 className='text-[23px] text-center text-white font-customBold mb-5'>
                                {t('third.lap.title4')}
                            </h3>

                            <p className='text-[12px] text-white text-center font-customRegular'>
                                {t('third.lap.title4.text')}
                            </p>
                        </div>
                        <div className='w-[100%] p-5 my-5 rounded-2xl bg-color-custom-orange-dark'>
                            <h3 className='text-[23px] text-center text-white font-customBold mb-5'>
                                {t('third.lap.title5')}
                            </h3>

                            <p className='text-[12px] text-white text-center font-customRegular'>
                                {t('third.lap.title5.text')}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {/* 5th section */}
            <div className='flex w-[100%] h-fit bg-colorOrangeDark py-10 justify-center'>
                <div className='w-[100%] pc-3:w-[1366px]'>
                <h3 className='mb-5 text-white text-[30px] font-customExtraBold text-center'>Life at Pendanaan</h3>

                <div className='flex w-[100%] h-[100%] items-center'>
                    <Swiper
                    spaceBetween={10}
                    slidesPerView={1}
                    speed={1000}
                    touchRatio={1.5}
                    effect={"slide"}
                    // loop
                    modules={[Pagination]}
                    breakpoints={{
                        375:{
                            slidesPerView:1
                        },
                        412:{
                            slidesPerView:1
                        },
                        540:{
                            slidesPerView:1
                        },
                        640: {
                            slidesPerView:2,
                        },
                        768: {
                            slidesPerView:2,
                        },
                        820: {
                            slidesPerView:3,
                        },
                        912: {
                            slidesPerView:3,
                        },
                        1024: {
                            slidesPerView:4,
                        },
                        1367: {
                            slidesPerView:5,
                        },
                    }}
                    pagination={{
                        el: '.swiper-pagination-three',
                        clickable: true,
                        type: 'bullets',
                        renderBullet: function () {
                            return(
                                `<span class="swiper-pagination-bullet"></span>`
                                )
                            }}}
                    autoplay={{ delay: 5000 }}
                    onBeforeInit={(swiper) => {
                        secondSwiperRef.current = swiper;
                    }} className='w-[100%] h-fit'>
                            <div className='flex w-[100%]'>
                                {
                                pictures.map((i)=>{
                                    return(
                                    <SwiperSlide>
                                    <div className={`mx-5 pc-3:mx-0 pc-2:mx-10 w-[90%] h-[350px] sm:w-[250px] sm:h-[250px] bg-white rounded-2xl ml-[${i.ml}] mr-[${i.mr}]`}>
                                        <div style={{
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center center',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundImage: `url(${require(`../../assets/images/${i.url}`)})`
                                            }} className='flex h-[300px] sm:h-[190px] rounded-t-2xl items-end bg-cover px-2'/>
                                        <h3 className='text-[14px] font-customBold mt-1 text-center'>{i.name}</h3>
                                    </div>
                                    </SwiperSlide>
                                    )})}
                            </div>

                        <div className='flex pt-10 pb-5 px-4 lg:px-14 justify-between'>
                            <button className='text-[32px] text-white z-20' onClick={() => secondSwiperRef.current?.slidePrev()}><HiOutlineArrowLongLeft/></button>
                            <span className='swiper-pagination-three flex justify-center mt-[13px]'/>
                            <button className='text-[32px] text-white z-20' onClick={() => secondSwiperRef.current?.slideNext()}><HiOutlineArrowLongRight/></button>
                        </div>
                    </Swiper>
                </div>
                </div>
            </div>

        <Poster2/>

        {/* <div className='fixed flex items-center justify-center left-0 top-0 bg-[#000] w-[100%] h-[100%] z-[9999]'>
            <div className='absolute top-[7%] right-[9%] rounded-full w-[50px] h-[50px] bg-white'></div>
            <img src={require(`../../assets/images/BACs.jpg`)} className='w-[80%] h-[80%] object-cover'/>
        </div> */}
        </>
    )
}