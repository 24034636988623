import React,{useState} from 'react'
import './LanguageToggle.css'
import { useTranslation } from 'react-i18next'

export default()=>{    
    const[isEnglish,setIsEnglish]=useState(false)
    const { t, i18n }=useTranslation()
    return(
        <label className='toggle-container'>
            <input type='checkbox' 
            // onClick={()=>{setIsEnglish(!isEnglish)}}
            onChange={()=>{
                setIsEnglish(!isEnglish)
                if(isEnglish){
                    i18n.changeLanguage('in-ID')
                }else{
                    i18n.changeLanguage('en')
                }
            }}/>
            <span className='title right-1'>IN</span> 
            <span className='title right-7'>EN</span>
            <span className={`indicator ${isEnglish?'transform transition-transform translate-x-[24px] bg-[url("./assets/images/english.png")] bg-cover':'transform transition-transform translate-x-[0px] bg-[url("./assets/images/indonesia.png")] bg-cover'}`}/>
        </label>
    )
}