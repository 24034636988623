import ReactPlayer from 'react-player'
import { useTranslation } from 'react-i18next'
import apk from '../../../assets/documents/KTAKilat.apk'

export default () =>{
    const {t,i18n} = useTranslation()
    return(
        <div className='w-[100%] h-fit py-14 bg-[#f6f6f6]'>
            <div className='grid lg:flex items-center px-5 lg:px-16 leading-tight justify-center lg:justify-between'>
                <div className='hidden lg:grid w-[30%]'>
                    <h3 className='flex-row text-[#000] text-[30px] font-customExtraBold'>{t('forth.home')}&nbsp;<span className='text-[#e77b30]'>KTA Kilat</span>?</h3>
                    <span className='text-[#327298] text-[14px] font-customBold mt-5'>{t('download.banner')}</span>
                    <div className='flex mt-3 gap-3'>
                        <a href='https:play.google.com/store/apps/details?id=com.ktakilat.loan' target='_blank' className='w-[140px]'>
                            <img src={require('../../../assets/images/android.png')}/>
                        </a>
                        
                        <a href={apk} target='_blank' className='hidden w-[140px]'>
                            <img src={require('../../../assets/images/webapp-orange.png')}/>
                        </a>
                    </div>
                </div>

                <ReactPlayer url={require('../../../assets/videos/ktakilat-video.mp4')}
                controls
                width= '100%'
                height='100%'
                frameBorder='0'
                allow='autoplay; encrypted-media'
                allowFullScreen
                title='video'
                playing={true}
                muted={true}
                className='ml-0 lg:ml-16 xl:ml-24'/>

                <div className='grid lg:hidden w-[100%] mt-5'>
                    <h3 className='text-[#000] text-[30px] font-customExtraBold'>{t('forth.home')}&nbsp;<span className='text-[#e77b30]'>KTA Kilat</span>?</h3>
                    <span className='text-[#327298] text-[16px] font-customBold'>{t('download.banner')}</span>
                    <div className='flex mt-3 gap-3'>
                        <a href='https:play.google.com/store/apps/details?id=com.ktakilat.loan' target='_blank' className='w-[140px]'>
                            <img src={require('../../../assets/images/android.png')}/>
                        </a>
                        
                        <a href={apk} target='_blank' className='hidden w-[140px]'>
                            <img src={require('../../../assets/images/webapp-orange.png')}/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}