import { useTranslation } from 'react-i18next'

export default ()=>{
    const {t,i18n} = useTranslation()
    return(
        <>
        <div className='w-screen h-[750px] bg-orange-400'>
            <p className='text-[26px] font-customRegular font-[900] text-slate-900 absolute top-[63px] left-[18px]'>Test</p>
            <p className='text-[25px] font-customRegular font-[200] text-slate-600 absolute top-16 left-[18px] z-10'>Test</p>
        </div>
        </>
    )
}