import React, {useContext, useState, useRef} from "react"
import { multiStepContext } from "../Step-Context"
import { useTranslation } from 'react-i18next'
import { BiChevronDown } from 'react-icons/bi'
import industry from './industry.json'
import job from './job.json'
import income from './income.json'
import fundSource from './fund-source.json'
import invesment from './invesment.json'
import axios from "axios"
import i18next from 'i18next'
import '../Application.css'

export default()=>{
    const {setStep, userData, setUserData, isPersonal} = useContext(multiStepContext)
    const {t} = useTranslation()

    const [openIndustry, setOpenIndustry] = useState(false)
    const [openJob, setOpenJob] = useState(false)
    const [openIncome, setOpenIncome] = useState(false)
    const [openSource, setOpenSource] = useState(false)
    const [openInstitutionSource, setOpenInstitutionSource] = useState(false)
    const [openInstitutionInvesment, setOpenInstitutionInvesment] = useState(false)
    const [openInvesment, setOpenInvesment] = useState(false)
    const [ektpImgInfo, setEktpImgInfo] = useState(null)

    console.log(userData)

    // axios
    const [post, setPost] = React.useState(null);
    const baseURL = "https://pendanaan.com/boss/kta/api/outer/v1/lender/recruit/individual/apply";
    const baseCorporateURL = "https://pendanaan.com/boss/kta/api/outer/v1/lender/recruit/institution/apply";

    function dateToString(day, month, year) {
        let strDay = day.toString();
        strDay = strDay.length > 1 ? strDay : '0' + strDay; 
        console.log("strDay=" + strDay)

        let strMonth = month.toString();
        strMonth = strMonth.length > 1 ? strMonth : '0' + strMonth;
        
        return year.toString() + "-" + strMonth + "-" + strDay;
    }
    
    const [errorIndustry, setErrorIndustry] = useState(false)
    const [errorOccupation, setErrorOccupation] = useState(false)
    const [errorIncome, setErrorIncome] = useState(false)
    const [errorSource, setErrorSource] = useState(false)
    const [errorInvesment, setErrorInvesment] = useState(false)
    
    function submitLender() {  
        console.log("ektpImgInfo" + JSON.stringify(userData['ektpImgInfo'])) ;     
        if(userData['industryId'] == null){
            setErrorIndustry(true)
        }else if(userData['jobId'] == null){
            setErrorOccupation(true)
        }else if(userData['incomeId'] == null){
            setErrorIncome(true)
        }else if(userData['sourceId'] == null){
            setErrorSource(true)
        }else if(userData['invesmentId'] == null){
            setErrorInvesment(false)
        }else{
        try{
            axios
            .post(baseURL, {
              "fullName": userData['fullName'],
              "birthDate": dateToString(userData['dayOfBirth'], userData['monthOfBirth'], userData['yearOfBirth']),
              "province": userData['provinceName'],
              "city": userData['cityName'],
              "postCode": userData['postalCode'],
              "homeAddress": userData['homeAddress'],
              "religion": userData['religionId'],
              "education": userData['educationId'],
              "email": userData['email'],
              "phoneNumber": userData['phoneNumber'],
              "industry": userData['industryId'],
              "occupation": userData['jobId'],
              "annualIncome": userData['incomeId'],
              "ektpImgInfo": userData['ektpImgInfo'],
              "selfieImgInfo": userData['selfieImgInfo'],
              "ektp": userData['ektp'],
              "sourceOfFund": userData['sourceId'],
              "investmentPurposes": userData['invesmentId'],
              "totalAsset": userData['totalAssets'],
              "browserInfo" : userData['browserInfo']
            }, {
                headers: {
                    'tenant': "KTAID",
                    'Content-Type': "application/json",
                    "Accept-Language": i18next.language
                }
            })
            .then((response) => {
              setPost(response.data)
              if(response.data.success){
                setStep(6)
              }else{
                window.alert(response.data.msg)
              }
              console.log(JSON.stringify(response.data.data))
            })
        }catch(e){
            console.log("error: " && e)
        }}
    }

    const [errorInstitutionSource, setErrorInstitutionSource] = useState(false)
    const [errorInstitutionPurpose, setErrorInstitutionPurpose] = useState(false)
        function submitLenderInstitution() {  
            if(userData['sourceName'] == '' || userData['sourceName'] == null){
                setErrorInstitutionSource(true)
            }else if(userData['invesmentName'] == '' || userData['invesmentName'] == null){
                setErrorInstitutionPurpose(true)
            }else{
            try{
                axios
                .post(baseCorporateURL, {
                  "institutionName": userData['institutionName'],
                  "institutionType": userData['typeId'],
                  "institutionBusinessType": userData['BusinessType'],
                  "institutionEstablishmentTime": userData['establishmentTime'],
                  "institutionEmail": userData['institutionEmail'],
                  "institutionPhoneNumber": userData['institutionPhoneNumber'],
                  "province": userData['provinceName'],
                  "city": userData['cityName'],
                  "postCode": userData['postalCode'],
                  "institutionAddress": userData['institutionAddress'],
                  "institutionDescription": userData['institutionDesc'],
                  "institutionDocumentType": userData['institutionDocumentType'],
                  "institutionDocumentInfo": userData['documentImgInfo'],
                  "amendedDate": userData['amendedDate'],
                  "amendedNumber": userData['amendedNumber'],
                  "leaderPrefix": userData['prefixId'],
                  "leaderNameOfEktp": userData['leaderName'],
                  "leaderPhoneNumber": userData['leaderPhoneNumber'],
                  "leaderPosition": userData['positionId'],
                  "sourceOfFund": userData['sourceName'],
                  "investmentPurposes": userData['invesmentName'],
                  "browserInfo" : userData['browserInfo'],
                  "totalAsset": userData['totalAssets']
                }, {
                    headers: {
                        'tenant': "KTAID",
                        'Content-Type': "application/json",
                        "Accept-Language": i18next.language
                    }
                })
                .then((response) => {
                  setPost(response.data)
                  if(response.data.success){
                    setStep(6)
                  }else{
                    window.alert(response.data.msg)
                  }
                  console.log(JSON.stringify(response.data.data))
                })
            }catch(e){
                console.log("error: " && e)
            }}
        }
        
        const industryMenu = useRef(null)
        const closeIndustryMenu = (e)=>{
            if(industryMenu.current && openIndustry && !industryMenu.current.contains(e.target)){
            setOpenIndustry(false)
            }
        }
        document.addEventListener('mousedown',closeIndustryMenu)

        const jobMenu = useRef(null)
        const closeJobMenu = (e)=>{
            if(jobMenu.current && openJob && !jobMenu.current.contains(e.target)){
            setOpenJob(false)
            }
        }
        document.addEventListener('mousedown',closeJobMenu)

        const incomeMenu = useRef(null)
        const closeIncomeMenu = (e)=>{
            if(incomeMenu.current && openIncome && !incomeMenu.current.contains(e.target)){
            setOpenIncome(false)
            }
        }
        document.addEventListener('mousedown',closeIncomeMenu)

        const sourceMenu = useRef(null)
        const closeSourceMenu = (e)=>{
            if(sourceMenu.current && openSource && !sourceMenu.current.contains(e.target)){
            setOpenSource(false)
            }
        }
        document.addEventListener('mousedown',closeSourceMenu)

        const invesmentMenu = useRef(null)
        const closeInvesmentMenu = (e)=>{
            if(invesmentMenu.current && openInvesment && !invesmentMenu.current.contains(e.target)){
            setOpenInvesment(false)
            }
        }
        document.addEventListener('mousedown',closeInvesmentMenu)

        const institutionSourceMenu = useRef(null)
        const closeInstitutionSourceMenu = (e)=>{
            if(institutionSourceMenu.current && openInstitutionSource && !institutionSourceMenu.current.contains(e.target)){
            setOpenInstitutionSource(false)
            }
        }
        document.addEventListener('mousedown',closeInstitutionSourceMenu)

        const institutionInvesmentMenu = useRef(null)
        const closeInstitutionInvesmentMenu = (e)=>{
            if(institutionInvesmentMenu.current && openInstitutionInvesment && !institutionInvesmentMenu.current.contains(e.target)){
            setOpenInstitutionInvesment(false)
            }
        }
        document.addEventListener('mousedown',closeInstitutionInvesmentMenu)

        console.log(userData)
        return(
        <>
        <div className={`${isPersonal ? 'block' : 'hidden'}`}>
        <div className="flex w-[100%] h-[100%] mt-20 justify-center">
            <div className="grid w-[70%] gap-5">
                <span className="text-[14px] font-customRegular text-gray-900 text-center">{t('loan.lender-funding-info-title')}</span>
            </div>
        </div>

        <div className="block lg:flex w-[100%] gap-5 mt-5">
        {/* Industry */}
        <div className="w-[100%] lg:w-[50%]" ref={industryMenu}>
                <div onClick={()=>{
                    setOpenIndustry(!openIndustry)
                }}
                className={`w-[100%] p-2 bg-white text-[14px] font-customRegular border flex items-center justify-between cursor-pointer rounded ${
                    userData['industryName'] == null ? 'text-gray-400' : 'text-gray-900'}
                    ${errorIndustry ? 'border-red-500' : 'border'}`}>
                    {userData['industryName'] ? userData['industryName'] : `${t('loan.lender-funding-info-industry')}`}
                    <BiChevronDown className={`text-gray-900 ${openIndustry && 'rotate-180'}`}/>
                </div>

                <ul className={`w-[90%] lg:w-[35%] bg-white text-[14px] font-customRegular mt-2 shadow-md overflow-y-auto ${openIndustry ? 'absolute max-h-60 border' : 'max-h-0'}`}>
                    {
                        industry.map((items)=>{
                            return(
                                <li key={items.industryId}
                                onClick={()=>{
                                    setOpenIndustry(!openIndustry)
                                    setUserData({...userData, "industryId" : items.industryId, "industryName" : items.industryName})
                                    setErrorIndustry(false)
                                }}
                                className="p-2 hover:bg-gray-100 cursor-pointer text-[14px] font-customRegular">{items.industryName}</li>
                            )
                        })
                    }
                </ul>
                <p className={`font-customRegular text-[12px] text-red-500 ${errorIndustry ? 'block' : 'hidden'}`}>{t('lender.error.industry')}</p>
            </div>

            {/* Occupation */}
            <div className="w-[100%] lg:w-[50%] mt-5 lg:mt-0" ref={jobMenu}>
                <div onClick={()=>{
                    setOpenJob(!openJob)
                }}
                className={`w-[100%] p-2 bg-white text-[14px] font-customRegular border flex items-center justify-between cursor-pointer rounded ${
                    userData['jobName'] == null ? 'text-gray-400' : 'text-gray-900'}
                    ${errorOccupation ? 'border-red-500' : 'border'}`}>
                    {userData['jobName'] ? userData['jobName'] : `${t('loan.lender-funding-info-occupation')}`}
                    <BiChevronDown className={`text-gray-900 ${openJob && 'rotate-180'}`}/>
                </div>

                <ul className={`w-[90%] lg:w-[35%] bg-white text-[14px] font-customRegular mt-2 shadow-md overflow-y-auto ${openJob ? 'absolute max-h-60 border' : 'max-h-0'}`}>
                    {
                        job.map((items)=>{
                            return(
                                <li key={items.jobId}
                                onClick={()=>{
                                    setOpenJob(!openJob)
                                    setUserData({...userData, "jobId" : items.jobId, "jobName" : items.jobName})
                                    setErrorOccupation(false)
                                }}
                                className="p-2 hover:bg-gray-100 cursor-pointer text-[14px] font-customRegular">{items.jobName}</li>
                            )
                        })
                    }
                </ul>
                <p className={`font-customRegular text-[12px] text-red-500 ${errorOccupation ? 'block' : 'hidden'}`}>{t('lender.error.job')}</p>
            </div>
        </div>

        <div className="flex w-[100%] mt-5">
        {/* Income */}
        <div className="w-[100%]" ref={incomeMenu}>
        <div onClick={()=>{
                setOpenIncome(!openIncome)
            }}
            className={`w-[100%] p-2 bg-white text-[14px] font-customRegular border flex items-center justify-between cursor-pointer rounded ${
                userData['incomeValue'] == null ? 'text-gray-400' : 'text-gray-900'}
                ${errorIncome ? 'border-red-500' : 'border'}`}>
                {userData['incomeValue'] ? userData['incomeValue'] : `${t('loan.lender-funding-info-income')}`}
                <BiChevronDown className={`text-gray-900 ${openIncome && 'rotate-180'}`}/>
            </div>

            <ul className={`w-[90%] lg:w-[73%] bg-white text-[14px] font-customRegular mt-2 shadow-md overflow-y-auto ${openIncome ? 'absolute max-h-60 border' : 'max-h-0'}`}>
                {
                    income.map((items)=>{
                        return(
                            <li key={items.incomeId}
                            onClick={()=>{
                                setOpenIncome(!openIncome)
                                setUserData({...userData, "incomeId" : items.incomeId, "incomeValue" : items.incomeValue})
                                setErrorIncome(false)
                            }}
                            className="p-2 hover:bg-gray-100 cursor-pointer text-[14px] font-customRegular">{items.incomeValue}</li>
                        )
                    })
                }
            </ul>
            <p className={`font-customRegular text-[12px] text-red-500 ${errorIncome ? 'block' : 'hidden'}`}>{t('lender.error.income')}</p>
        </div>
        </div>

        <div className="flex w-[100%] mt-5">
        {/* Source of fund */}
        <div className="w-[100%]" ref={sourceMenu}>
        <div onClick={()=>{
                setOpenSource(!openSource)
            }}
            className={`w-[100%] p-2 bg-white text-[14px] font-customRegular border flex items-center justify-between cursor-pointer rounded ${
                userData['sourceName'] == null ? 'text-gray-400' : 'text-gray-900'}
                ${errorSource ? 'border-red-500' : 'border'}`}>
                {userData['sourceName'] ? userData['sourceName'] : `${t('loan.lender-funding-info-fund-source')}`}
                <BiChevronDown className={`text-gray-900 ${openSource && 'rotate-180'}`}/>
            </div>

            <ul className={`w-[90%] lg:w-[73%] bg-white text-[14px] font-customRegular mt-2 shadow-md overflow-y-auto ${openSource ? 'absolute max-h-60 border' : 'max-h-0'}`}>
                {
                    fundSource.map((items)=>{
                        return(
                            <li key={items.sourceId}
                            onClick={()=>{
                                setOpenSource(!openSource)
                                setUserData({...userData, "sourceId" : items.sourceId, "sourceName" : items.sourceName})
                                setErrorSource(false)
                            }}
                            className="p-2 hover:bg-gray-100 cursor-pointer text-[14px] font-customRegular">{items.sourceName}</li>
                        )
                    })
                }
            </ul>
            <p className={`font-customRegular text-[12px] text-red-500 ${errorSource ? 'block' : 'hidden'}`}>{t('lender.error.soure')}</p>
        </div>
        </div>

        <div className="flex w-[100%] mt-5">
        {/* Invesment purpose */}
        <div className="w-[100%]" ref={invesmentMenu}>
        <div onClick={()=>{
                setOpenInvesment(!openInvesment)
            }}
            className={`w-[100%] p-2 bg-white text-[14px] font-customRegular border flex items-center justify-between cursor-pointer rounded ${
                userData['invesmentName'] == null ? 'text-gray-400' : 'text-gray-900'}
                ${errorInvesment ? 'border-red-500' : 'border'}`}>
                {userData['invesmentName'] ? userData['invesmentName'] : `${t('loan.lender-funding-info-fund-invesment')}`}
                <BiChevronDown className={`text-gray-900 ${openInvesment && 'rotate-180'}`}/>
            </div>

            <ul className={`w-[90%] lg:w-[73%] bg-white text-[14px] font-customRegular mt-2 shadow-md overflow-y-auto ${openInvesment ? 'absolute max-h-60 border' : 'max-h-0'}`}>
                {
                    invesment.map((items)=>{
                        return(
                            <li key={items.invesmentId}
                            onClick={()=>{
                                setOpenInvesment(!openInvesment)
                                setUserData({...userData, "invesmentId" : items.invesmentId, "invesmentName" : items.invesmentName})
                                setErrorInvesment(false)
                            }}
                            className="p-2 hover:bg-gray-100 cursor-pointer text-[14px] font-customRegular">{items.invesmentName}</li>
                        )
                    })
                }
            </ul>
            <p className={`font-customRegular text-[12px] text-red-500 ${errorInvesment ? 'block' : 'hidden'}`}>{t('lender.error.invesment')}</p>
        </div>
        </div>

        <input
        type="number"
        value={userData['totalAssets']}
        onChange={(e)=>setUserData({...userData, "totalAssets": e.target.value})}
        className={`appearance-none mt-5 block w-[100%] bg-white text-gray-900 border rounded py-2 px-2 leading-tight focus:outline-none focus:bg-white text-[14px] font-customRegular`} placeholder={t('loan.lender-type-individual-assets')}/>

        <div className="flex w-[100%] my-5 gap-5">
                <button class="w-[50%] bg-gray-500 border text-white font-customBold py-2 px-4 rounded shadow-md">
                    <div className="flex items-center justify-center"
                    onClick={()=>(
                        setStep(4)
                    )}>
                        {t('loan.back')}
                    </div>
                </button>

                <button class="w-[50%] bg-[#E77B30] border text-white font-customBold py-2 px-4 rounded shadow-md">
                    <div className="flex items-center justify-center"
                    onClick={()=>(
                        submitLender()
                    )}>
                        {t('loan.submit')}
                    </div>
                </button>
            </div>
            </div>




            {/* Corporate */}
            <div className={`${isPersonal ? 'hidden' : 'block'}`}>
        <div className="flex w-[100%] h-[100%] mt-20 justify-center">
            <div className="grid w-[70%] gap-5">
                <span className="text-[14px] font-customRegular text-gray-900 text-center">{t('loan.lender-funding-info-title')}</span>
            </div>
        </div>

        <div className="w-[100%] mt-5">
        {/* Source of fund */}
            <input
            value={userData['sourceName']}
            onChange={(e)=>setUserData({...userData, "sourceName": e.target.value},
            setErrorInstitutionSource(false))}
            className={`appearance-none text-[14px] font-customRegular block w-full bg-white text-gray-900 border rounded py-2 px-2 leading-tight focus:outline-none focus:bg-white
            ${errorInstitutionSource ? 'border-red-500' : 'border'}`} type="text" placeholder={t('loan.lender-funding-info-fund-source')}/>
            <p className={`font-customRegular mt-1 text-[12px] text-red-500 ${errorInstitutionSource ? 'block' : 'hidden'}`}>{t('lender.error.institution.leader.source')}</p>
        </div>

        <div className="w-[100%] mt-5">
        {/* Invesment purpose */}
        <textarea 
            value={userData['invesmentName']}
            onChange={(e)=>setUserData({...userData, "invesmentName": e.target.value},
            setErrorInstitutionPurpose(false))}
            className={`appearance-none text-[14px] font-customRegular block w-full bg-white text-gray-900 border rounded py-2 px-2 leading-tight focus:outline-none focus:bg-white resize-none
            ${errorInstitutionPurpose ? 'border-red-500' : 'border'}`} type="text" placeholder={t('loan.lender-funding-info-fund-invesment')}/>
            <p className={`font-customRegular text-[12px] mt-1 text-red-500 ${errorInstitutionPurpose ? 'block' : 'hidden'}`}>{t('lender.error.institution.leader.purpose')}</p>
        </div>

        <input
        type="number"
        value={userData['totalAssets']}
        onChange={(e)=>setUserData({...userData, "totalAssets": e.target.value})}
        className={`appearance-none text-[14px] font-customRegular mt-5 block w-[100%] bg-white text-gray-900 border rounded py-2 px-2 leading-tight focus:outline-none focus:bg-white`} placeholder={t('loan.lender-type-individual-assets')}/>

        <div className="flex w-[100%] my-5 gap-5">
                <button class="w-[50%] bg-gray-500 border text-white font-customBold py-2 px-4 rounded shadow-md">
                    <div className="flex items-center justify-center"
                    onClick={()=>(
                        setStep(4)
                    )}>
                        {t('loan.back')}
                    </div>
                </button>

                <button class="w-[50%] bg-[#E77B30] border text-white font-customBold py-2 px-4 rounded shadow-md">
                    <div className="flex items-center justify-center"
                    onClick={()=>(
                        submitLenderInstitution()
                    )}>
                        {t('loan.submit')}
                    </div>
                </button>
            </div>
            </div>
        </>
    )
}