import React, { useState, useEffect, Component } from 'react'
import './About.css'
import Tab1 from './tab1/Tab-1'
import Tab2 from './tab2/Tab-2'
import Poster from '../footer/Poster-1'
import { useTranslation } from 'react-i18next'
import AOS from "aos"
import "aos/dist/aos.css"

export default()=>{
    useEffect(() => {
        AOS.init()
        AOS.refresh()
      }, [])

    const {t,i18n} = useTranslation()

    const [actived, setActived] = useState(1)
    const toggle = (index) =>{
        setActived(index)
    }
    return(
        <>
        <div className='flex w-[100%] h-fit items-end pt-[90px]'>
            <div className='w-[100%] h-[100%]'>
                {/* <div className='flex px-5 md:px-10 lg:px-14'>
                    <button className={`px-3 py-3 ${ actived === 1 ? 'tabs active' : 'tabs'}`}
                    onClick={() =>{
                        toggle(1)
                    }}>{t('first.about.pendanaan.title')}</button>
                    <button className={`px-3 py-3 ${ actived === 2 ? 'tabs active' : 'tabs'}`}
                    onClick={() =>{
                        toggle(2)
                    }}>{t('first.about.ktakilat.title')}</button>
                </div> */}

                <div className='w-[100%] h-[100%] bg-colorBackgroundGray'>
                    <div className={`w-[100%] h-fit ${ actived === 1 ? 'contents active' : 'contents'}`}>
                        <Tab1/>
                    </div>

                    <div className={`w-[100%] h-fit ${ actived === 2 ? 'contents active' : 'contents'}`}>
                        <Tab2/>
                    </div>
                </div>
            </div>
        </div>

        <Poster/>
        </>
    )
}