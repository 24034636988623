import { useTranslation } from 'react-i18next'
import React from 'react'
import { Link, useMatch, useResolvedPath } from 'react-router-dom'

export default()=>{
    const {t,i18n} = useTranslation()
    return(
        <div className="flex justify-center">
        <div className="grid bg-white w-[70%] h-fit border rounded-2xl justify-items-center shadow-md">
        <img src={require('../../../../assets/images/congrats.png')} className='w-[200px] place-self-center mt-5'/>  
        <h3 className='text-[#000] text-center text-[30px] font-customExtraBold my-5'>{t('lender.error.institution.congrats')}</h3>
        <span className='text-[14px] font-customBold px-10 text-center'>{t('lender.error.institution.congrats.subtitle')}</span>
        <p className={`font-customRegular text-[12px] text-gray-900`}>{t('lender.error.institution.congrats.text')}</p>
        <CustomLink to = '/' className='flex w-[100%] bg-colorOrangeDark hover:bg-[#ff9346] hover:opacity-90 active:bg-[#ffae74] text-white font-customBold text-[14px] py-3 rounded-md items-center justify-center my-10 px-3'>{t('home.lender')}</CustomLink>
        </div>
        </div>
    )
}

function CustomLink({ to, children, ...props }){
    const resolvePath = useResolvedPath(to)
    const isActive = useMatch({path: resolvePath.pathname, end: true})
    return(
        <li className = {`${isActive ? 'active' : ''} list-none`}>
            <Link to = {to} {...props}>{children}</Link>
        </li>
    )
}