import { useState } from 'react'
import Tab1 from './tab1/Tab-1'
import Tab2 from './tab2/Tab-2'
import Tab3 from './tab3/Tab-3'
import Tab4 from './tab4/Tab-4'
import {GoSearch} from 'react-icons/go'
import './Faq.css'
import Poster1 from '../footer/Poster-1'
import { useTranslation } from 'react-i18next'
import { FaPhoneAlt } from "react-icons/fa"
import { GrMail } from 'react-icons/gr'
import { BsGlobe } from "react-icons/bs";

export default() =>{
    const {t,i18n} = useTranslation()
    const [actived, setActived] = useState(1)
    const toggle = (index) =>{
        setActived(index)
    }
    return(
        <>
        <div className='w-[100%] flex justify-center'>
            <div className='w-[100%] pc-3:w-[1366px]'>

            <div className='grid md:flex w-[100%] h-fit items-end justify-between pt-[90px] px-5 md:px-10 lg:px-14'>
            <div className='flex flex-nowrap overflow-auto whitespace-nowrap'>
                <button className={`px-3 py-3 ${ actived === 1 ? 'tabs active' : 'tabs'}`}
                onClick={() =>{
                    toggle(1)
                    }}>{t('first.faq.loan.title')}</button>
                    
                <button className={`px-3 py-3 ${ actived === 2 ? 'tabs active' : 'tabs'}`}
                onClick={() =>{
                    toggle(2)
                    }}>{t('second.faq.return.title')}</button>
                <button className={`px-3 py-3 ${ actived === 3 ? 'tabs active' : 'tabs'}`}
                onClick={() =>{
                    toggle(3)
                    }}>{t('third.faq.general.title')}</button>
                <button className={`px-3 py-3 ${ actived === 4 ? 'tabs active' : 'tabs'}`}
                onClick={() =>{
                    toggle(4)
                    }}>{t('forth.faq.general.title')}</button>
            </div>

        {/* Search */}
        <div className='hidden md:hidden w-[30%] px-5 pt-5 text-gray-500'>
            <a href='#' role='button' className='flex w-[100%] h-[50px] items-center justify-between px-5 bg-white border border-solid border-gray-300 rounded-xl font-customRegular'>
                Cari <GoSearch/>
            </a>
        </div>
        </div>
        
        {/* Search */}
        <div className='hidden md:hidden w-[100%] px-5 pt-5 text-gray-500'>
            <a href='#' role='button' className='flex w-[100%] h-[50px] items-center justify-between px-5 bg-white border border-solid border-gray-300 rounded-xl font-customRegular'>
                Cari <GoSearch/>
            </a>
        </div>

        <div className='w-[100%] h-[100%]'>
            <div className={`w-[100%] h-fit ${ actived === 1 ? 'contents active' : 'contents'}`}>
                <Tab1/>
            </div>

            <div className={`w-[100%] h-fit ${ actived === 2 ? 'contents active' : 'contents'}`}>
                <Tab2/>
            </div>

            <div className={`w-[100%] h-fit ${ actived === 3 ? 'contents active' : 'contents'}`}>
                <Tab3/>
            </div>

            <div className={`w-[100%] h-fit ${ actived === 4 ? 'contents active' : 'contents'}`}>
                <Tab4/>
            </div>
        </div>
            </div>

        </div>

        <div className='relative flex justify-center w-[100%] h-fit bg-[#F6F6F6] font-customRegular text-[14px]'>
            <div className='w-[100%] px-5 md:px-10 lg:px-14 pc-3:w-[1366px] mt-10'>
                <h1 className='font-customExtraBold text-[23px]'><b>{t('faq.footer1.title1')}<br/>{t('faq.footer1.title2')}</b></h1>
                <p className='mt-[35px]'>
                {t('faq.footer1.text1')}
                <br/>
                <br/>
                <b>{t('faq.footer1.text2')}</b>
                <br/>
                {t('faq.footer1.text3')}
                <br/>
                <br/>
                <b>{t('faq.footer1.text4')}</b>
                <br/>
                {t('faq.footer1.text5')}
                <br/>
                <br/>
                <b>{t('faq.footer1.text6')}</b>
                <br/>
                {t('faq.footer1.text7')}
                <br/>
                1. {t('faq.footer1.text8')}
                <br/>
                2. {t('faq.footer1.text9')}
                <br/>
                3. {t('faq.footer1.text10')}
                <br/>
                <br/>
                <b>{t('faq.footer1.text11')}</b>
                <br/>
                1. {t('faq.footer1.text12')}
                <br/>
                2. {t('faq.footer1.text13')}
                <br/>
                3. {t('faq.footer1.text13')}
                </p>

                <img src={require('../../assets/images/heho.png')} alt='iso-1' className='hidden pc-1:block absolute bottom-0 right-[90px] pc-3:right-[350px] h-[200px] object-cover'/>
            </div>
        </div>

        <div className='flex pc-1:hidden justify-center pt-10 bg-[#F6F6F6]'>
            <img src={require('../../assets/images/heho.png')} alt='iso-1' className='h-[200px] object-cover'/>
        </div>

        <div className='flex relative justify-center w-[100%] h-fit bg-[#F6F6F6] font-customRegular text-[14px]'>
            <div className='block pc-1:flex justify-between items-center w-[100%] pc-3:w-[1366px] my-10'>
                <div className='pl-5 md:pl-10 lg:pl-14'>
                    <h1 className='text-[16px] text-[#E77B30]'><b>Hubungi LAPS SJK Melalui:</b></h1>
                    <p>Gedung Menara Karya lt. 25 Unit G-H Jl. HR. Rasuna Said<br/>Blok X-5 Kav 1-2 Jakarta 12950</p>
                </div>

                <div className='hidden pc-1:flex items-center gap-3 pr-5 md:pr-10 lg:pr-14 text-[12px]'>
                    <div className='flex items-center gap-3 mt-3 bg-[#E77B30] px-10 py-2 rounded-lg'>
                        <span className='text-white font-customRegular text-[18px]'><FaPhoneAlt/></span>
                        <a href='tel:+62212527700' className='text-white font-customRegular'>021-2527700</a>
                    </div>

                    <div className='flex items-center gap-3 mt-3 bg-[#E77B30] px-10 py-2 rounded-lg'>
                        <span className='text-white font-customRegular text-[18px]'><GrMail/></span>
                        <a href='mailto:info@lapssjk.id' className='text-white font-customRegular'>info@lapssjk.id</a>
                    </div>

                    <div className='flex items-center gap-3 mt-3 bg-[#E77B30] px-10 py-2 rounded-lg'>
                        <span className='text-white font-customRegular text-[18px]'><BsGlobe/></span>
                        <a href='https://lapssjk.id/' className='text-white font-customRegular'>https://lapssjk.id/</a>
                    </div>
                </div>

                <div className='block pc-1:hidden mx-5'>
                    <div className='flex justify-center items-center gap-3 mt-3 bg-[#E77B30] px-10 py-2 rounded-lg'>
                        <span className='text-white font-customRegular text-[18px]'><FaPhoneAlt/></span>
                        <a href='tel:+62212527700' className='text-white font-customRegular'>021-2527700</a>
                    </div>

                    <div className='flex justify-center items-center gap-3 mt-3 bg-[#E77B30] px-10 py-2 rounded-lg'>
                        <span className='text-white font-customRegular text-[18px]'><GrMail/></span>
                        <a href='mailto:info@lapssjk.id' className='text-white font-customRegular'>info@lapssjk.id</a>
                    </div>

                    <div className='flex justify-center items-center gap-3 mt-3 bg-[#E77B30] px-10 py-2 rounded-lg'>
                        <span className='text-white font-customRegular text-[18px]'><BsGlobe/></span>
                        <a href='https://lapssjk.id/' className='text-white font-customRegular'>https://lapssjk.id/</a>
                    </div>
                </div>
            </div>
        </div>

        <Poster1/>
        </>
    )
}