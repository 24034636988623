import React, { useState, useRef } from 'react'
import { FaRegStar, FaStar } from 'react-icons/fa'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay, Pagination } from 'swiper'
import { HiOutlineArrowLongLeft, HiOutlineArrowLongRight } from 'react-icons/hi2'
import './Fifth.css'
import 'swiper/swiper-bundle.min.css'
import 'swiper/css'
import { useTranslation } from 'react-i18next'

export default ()=>{
    const {t,i18n} = useTranslation()
    const swiperRef = useRef()
    const [actived, setActived] = useState(1)
    const toggle = (index) =>{
        setActived(index)
    }
    SwiperCore.use([Autoplay, Pagination])

    const items1 = [{
            'url':'kalsel.jpg',
            'name':'Zaenal Helmi',
            'job':<div>Karyawan Swasta<br/>Kab. Banjar, Kalimantan Selatan<br/><br/></div>,
            'star': <div className='flex'><FaStar/><FaStar/><FaStar/><FaStar/><FaRegStar/></div>,
            'desc':'“Cukup puas tetapi ingin agar limit pinjaman nya bisa terus naik karena beliau sudah sering pinjam di aplikasi namun limitnya tidak naik lagi.”',
            'date':'17 April 2023',
            'delay': '200'
        },
        {
            'url':'banten.jpeg',
            'name':'Acep Ahmad Fauzi',
            'job':<div>Karyawan Swasta<br/>Tangerang, Banten<br/><br/></div>,
            'star': <div className='flex'><FaStar/><FaStar/><FaStar/><FaStar/><FaStar/></div>,
            'desc':'“Cukup puas dengan aplikasi, tidak ada komentar atau kritik.”',
            'date':'28 Desember 2022',
            'delay': '400'
        },
        {
            'url':'bogor.jpg',
            'name':'Dewi Alawiyah',
            'job':<div>Ibu Rumah Tangga<br/>Kab. Bogor, Jawa Barat<br/><br/></div>,
            'star': <div className='flex'><FaStar/><FaStar/><FaStar/><FaStar/><FaRegStar/></div>,
            'desc':'“Lancar dan tidak ada kendala, cepat cair dana nya dan puas sama produknya.”',
            'date':'23 September 2022',
            'delay': '600'
        },
        {
            'url':'mie_laksa.jpg',
            'name':'Ali Hazrudin',
            'job':<div>Karyawan Swasta<br/>Tangerang, Banten<br/><br/></div>,
            'star': <div className='flex'><FaStar/><FaStar/><FaStar/><FaStar/><FaStar/></div>,
            'desc':'“Pinjaman cepat dan tidak ribet. Kritik nya tenornya terlalu singkat hanya per 2 minggu sekali, ingin dapat pilihan tenor cicilan nya per satu bulan sekali. Dan kritik juga terkait dengan ceklis biru saat pemilihan limit pinjaman yang tidak bisa dihilangkan, yaitu di limit 2.6jt.”',
            'date':'31 Maret 2023',
            'delay': '800'
        }
    ]

    const items2 = [{
            'url':'empty-user.png',
            'name':'Sari',
            'job':'Karyawan Bank',
            'star': <div className='flex'><FaStar/><FaStar/><FaStar/><FaStar/></div>,
            'desc':'“Saya sangat puas dengan layanan pinjaman online ini. Tidak hanya cepat dan mudah, tetapi juga aman dan terpercaya. Saya akan menggunakan layanan ini lagi di masa depan jika membutuhkan dana tambahan.”',
            'date':'5 Desember 2022'
        },
        {
            'url':'empty-user.png',
            'name':'Rudi',
            'job':'Karyawan Swasta',
            'star': <div className='flex'><FaStar/><FaStar/><FaStar/><FaStar/></div>,
            'desc':'“Pinjaman online ini memberikan saya kemudahan dan kecepatan dalam mendapatkan dana yang dibutuhkan. Proses pengajuan yang mudah dan persetujuan cepat membuat saya merasa puas dengan layanan ini. Terima kasih atas bantuan dan pelayanannya yang baik!”',
            'date':'19 Januari 2023'
        }
    ]

    const lowestItems = [{
            'title':`${t('forth.about.ktakilat.tab.text1')}`,
            'value':'Rp. 300,000'
        },
        {
            'title':`${t('forth.about.ktakilat.tab.text2')}`,
            'value':'Rp. 27,000'
        },
        {
            'title':`${t('forth.about.ktakilat.tab.text3')}`,
            'value':'Rp. 327,000'
        },
        {
            'title':`${t('forth.about.ktakilat.tab.text4')}`,
            'value':'0,30%'
        }
    ]

    const highestItems = [{
            'title':`${t('forth.about.ktakilat.tab.text1')}`,
            'value':'Rp. 9,000,000'
        },
        {
            'title':`${t('forth.about.ktakilat.tab.text2')}`,
            'value':'Rp. 810,000'
        },
        {
            'title':`${t('forth.about.ktakilat.tab.text3')}`,
            'value':'Rp. 9,810,000'
        },
        {
            'title':`${t('forth.about.ktakilat.tab.text4')}`,
            'value':'0,30%'
        }
    ]
    return(
        <>
        <div className='grid sm:flex bg-[#f6f6f6] justify-center py-10'>
            <div className='w-[100%] pc-3:w-[1366px]'>
                <h3 className='text-[#000] text-[30px] font-customExtraBold text-center pb-5'>{t('forth.about.ktakilat.title')}</h3>

                <div className='w-[100%] h-fit'>
                <div className='flex justify-center pt-5 px-5 xl:px-10'>
                    <button className={`py-2 md:py-5 px-3 w-[50%] rounded-l-lg ${ actived === 1 ? 'tab active' : 'tab'}`}
                    onClick={() =>{
                        toggle(1)
                    }}>{t('forth.about.ktakilat.tab1')}</button>
                    <button className={`py-2 md:py-5 px-3 w-[50%] rounded-r-lg ${ actived === 2 ? 'tab active' : 'tab'}`}
                    onClick={() =>{
                        toggle(2)
                    }}>{t('forth.about.ktakilat.tab2')}</button>
                </div>

                {/* Content-1 */}
                <div className={`px-5 lg:px-10 pb-10 ${ actived === 1 ? 'content active' : 'content'} font-customRegular`}>
                    <div className='grid lg:flex lg:flex-wrap lg:justify-center'>
                        {
                            lowestItems.map((i)=>{
                                return(
                                    <div className='w-[100%] lg:w-[50%] h-[50px] mt-10'>
                                        <h3 className='text-[16px] font-customBold'>{i.title}</h3>
                                        <h1 className='text-[30px] text-colorOrangeDark font-customBold'>{i.value}</h1>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                {/* Content-2 */}
                <div className={`px-5 lg:px-10 pb-10 ${ actived === 2 ? 'content active' : 'content'} font-customRegular`}>
                    <div className='grid lg:flex lg:flex-wrap lg:justify-center'>
                        {
                            highestItems.map((i)=>{
                                return(
                                    <div className='w-[100%] lg:w-[50%] h-[50px] mt-10'>
                                        <h3 className='text-[16px] font-customBold'>{i.title}</h3>
                                        <h1 className='text-[30px] text-colorOrangeDark font-customBold'>{i.value}</h1>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            </div>
        </div>
        </>
    )
}