import React, {useContext, useState, useRef} from "react"
import { multiStepContext } from "../Step-Context"
import { BiChevronDown } from 'react-icons/bi'
import { BsFillExclamationCircleFill } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'
import religion from "./religion.json"
import education from "./education.json"
import city from './city.json'
import province from './province.json'
import validator from 'validator'
import { AiOutlineSearch } from 'react-icons/ai'
import '../Application.css'

  function days(year, month){
    let days=[]
    for(let dayCount = 1; dayCount <= new Date(year, month, 0).getDate(); dayCount++)
    days.push((<option value={dayCount} key={dayCount}>{dayCount}</option>))
    
    return days
  }

export default()=>{
    const {setStep, userData, setUserData} = useContext(multiStepContext)
    const {t} = useTranslation()

    const [openDate, setOpenDate] = useState(false)
    const [openMonth, setOpenMonth] = useState(false)
    const [openYear, setOpenYear] = useState(false)
    const month = [
        {
            id:1,
            name: "January"
        },{
            id:2,
            name: "February"
        },{
            id:3,
            name: "March"
        },{
            id:4,
            name: "April"
        },{
            id:5,
            name: "May"
        },{
            id:6,
            name: "June"
        },{
            id:7,
            name: "July"
        },{
            id:8,
            name: "August"
        },{
            id:9,
            name: "September"
        },{
            id:10,
            name: "October"
        },{
            id:11,
            name: "November"
        },{
            id:12,
            name: "December"
        }]
    const currentYear = new Date().getFullYear()
    let year = []
         for(let yearCount = currentYear; yearCount >= 1900; yearCount--)
         year.push((<option value={yearCount} key={yearCount}>{yearCount}</option>))
        
    const [openReligion, setOpenReligion] = useState(false)
    const [openEducation, setOpenEducation] = useState(false)
    const [openCity, setOpenCity] = useState(false)
    const [openProvince, setOpenProvince] = useState(false)
    const [cityInputValue, setCityInputValue] = useState("")
    const [provinceInputValue, setProvinceInputValue] = useState("")

    const [errorFullName, setErrorFullName] = useState(false)
    const [errorDateOfBirth, setErrorDateOfBirth] = useState(false)
    const [errorAddress, setErrorAddress] = useState(false)
    const [errorReligion, setErrorReligion] = useState(false)
    const [errorEducation, setErrorEducation] = useState(false)
    const [errorEmail, setErrorEmail] = useState(false)
    const [errorPostalCode, setErrorPostalCode] = useState(false)
    const [errorPhone, setErrorPhone] = useState(false)
    const [errorPhoneLength, setErrorPhoneLength] = useState(false)
    const [errorAge, setErrorAge] = useState(false)
    const [errorCity, setErrorCity] = useState(false)
    const [errorProvince, setErrorProvince] = useState(false)
    
    const onSubmit=()=>{
        if(userData['fullName'] == '' || userData['fullName'] == null){
            setErrorFullName(true)
        }else if(userData['dayOfBirth'] == null){
            setErrorDateOfBirth(true)
        }else if(getAge(dateToString(userData['dayOfBirth'], userData['monthOfBirth'], userData['yearOfBirth'])) < 17){
            setErrorAge(true)
        }else if(userData['homeAddress'] == '' || userData['homeAddress'] == null){
            setErrorAddress(true)
        }else if(userData['cityId'] == null){
            setErrorCity(true)
        }else if(userData['provinceId'] == null){
            setErrorProvince(true)
        }else if(userData['postalCode'] == '' || userData['postalCode'] == null){
            setErrorPostalCode(true)
        }else if(userData['religionId'] == null){
            setErrorReligion(true)
        }else if(userData['educationId'] == null){
            setErrorEducation(true)
        }else if(userData['email'] == null || userData['email'] == '' || !validator.isEmail(userData['email'])){
            setErrorEmail(true)
        }else if(userData['phoneNumber'] == null || userData['phoneNumber'] == '' || !validator.isMobilePhone(userData['phoneNumber'])){
            setErrorPhone(true)
        }else if(userData['phoneNumber'].toString().length < 9 || userData['phoneNumber'].toString().length > 13){
            setErrorPhoneLength(true)
        }else{
            setStep(4)
        }
    }   

    const yearMenu = useRef(null)
    const closeYearMenu = (e)=>{
        if(yearMenu.current && openYear && !yearMenu.current.contains(e.target)){
        setOpenYear(false)
        }
    }
    document.addEventListener('mousedown',closeYearMenu)

    const monthMenu = useRef(null)
    const closeMonthMenu = (e)=>{
        if(monthMenu.current && openMonth && !monthMenu.current.contains(e.target)){
        setOpenMonth(false)
        }
    }
    document.addEventListener('mousedown',closeMonthMenu)

    const dayMenu = useRef(null)
    const closeDayMenu = (e)=>{
        if(dayMenu.current && openDate && !dayMenu.current.contains(e.target)){
        setOpenDate(false)
        }
    }
    document.addEventListener('mousedown',closeDayMenu)

    const religionMenu = useRef(null)
    const closeReligionMenu = (e)=>{
        if(religionMenu.current && openReligion && !religionMenu.current.contains(e.target)){
        setOpenReligion(false)
        }
    }
    document.addEventListener('mousedown',closeReligionMenu)

    const educationMenu = useRef(null)
    const closeEducationMenu = (e)=>{
        if(educationMenu.current && openEducation && !educationMenu.current.contains(e.target)){
            setOpenEducation(false)
        }
    }
    document.addEventListener('mousedown',closeEducationMenu)

    const cityMenu = useRef(null)
    const closeCityMenu = (e)=>{
        if(cityMenu.current && openCity && !cityMenu.current.contains(e.target)){
            setOpenCity(false)
        }
    }
    document.addEventListener('mousedown',closeCityMenu)

    const provinceMenu = useRef(null)
    const closeProvinceMenu = (e)=>{
        if(provinceMenu.current && openProvince && !provinceMenu.current.contains(e.target)){
            setOpenProvince(false)
        }
    }
    document.addEventListener('mousedown',closeProvinceMenu)

    function dateToString(day, month, year) {
        console.log("day=" + day)
        let strDay = day.toString();
        strDay = strDay.length > 1 ? strDay : '0' + strDay; 
        console.log("strDay=" + strDay)

        console.log("month=" + month)
        let strMonth = month.toString();
        strMonth = strMonth.length > 1 ? strMonth : '0' + strMonth;
        console.log("strMonth=" + strMonth)
        
        return year.toString() + "-" + strMonth + "-" + strDay;
    }

    function getAge(dateString) {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        console.log("age=" + age)
        return age;
    }

    console.log(userData)
    return(
        <>
        <div className="flex w-[100%] h-fit p-5 bg-gray-200 items-center">
        <BsFillExclamationCircleFill className="block pc-1:hidden text-[150px] text-gray-900"/>
            <BsFillExclamationCircleFill className="hidden pc-1:block text-[32px] text-gray-900"/>
            <span className='text-[14px] font-customRegular text-gray-900 ml-5'>{t('loan.agreement')}</span>
        </div>

        <div className="w-[100%] my-5">
            <input
            value={userData['fullName']}
            onChange={
                (e)=>
                setUserData({...userData, "fullName": e.target.value},
                setErrorFullName(false))
            }

            className={`text-[14px] font-customRegular appearance-none block  w-full bg-white text-gray-900 border rounded py-2 px-2 leading-tight focus:outline-none focus:bg-white ${errorFullName ? 'border-red-500' : 'border'}`} type="text" placeholder={t('loan.lender-type-individual-fullname')}/>
            <p className={`font-customRegular text-[12px] mt-1 text-red-500 ${errorFullName ? 'block' : 'hidden'}`}>{t('lender.error.fullname')}</p>
        </div>

        <div className="w-[100%] flex gap-5">
            {/* Year */}
            <div className="w-[25%]" ref={yearMenu}>
                <div onClick={()=>{
                    setOpenYear(!openYear)
                }}
                className={`w-[100%] p-2 bg-white text-[14px] font-customRegular border flex items-center justify-between cursor-pointer rounded ${
                    userData['yearOfBirth'] == null ? 'text-gray-400' : 'text-gray-900'}
                    ${errorDateOfBirth || errorAge ? 'border-red-500' : 'border'}`}>
                    {userData['yearOfBirth'] ? userData['yearOfBirth'] : t('loan.lender-type-individual-year')}
                    <BiChevronDown className={`text-gray-900 ${openYear && 'rotate-180'}`}/>
                </div>

                <ul className={`w-[20%] lg:w-[15%] bg-white text-[14px] font-customRegular mt-2 shadow-md overflow-y-auto ${openYear ? 'absolute max-h-60 border' : 'max-h-0'}`}>
                    {
                        year.map((items)=>{
                            return(
                                <li key={items}
                                onClick={()=>{
                                    setUserData({...userData, "yearOfBirth" : items.key})
                                    setOpenYear(!openYear)
                                    setErrorDateOfBirth(false)
                                    setErrorAge(false)
                                }}
                                className="p-2 hover:bg-gray-100 cursor-pointer text-[14px] font-customRegular">{items}</li>
                            )
                        })
                    }
                </ul>
            </div>

            {/* Month */}
            <div className="w-[50%]" ref={monthMenu}>
                <div onClick={()=>{
                    setOpenMonth(!openMonth)
                }}
                className={`w-[100%] p-2 bg-white text-[14px] font-customRegular border flex items-center justify-between cursor-pointer rounded ${
                    userData['monthOfBirthName'] == null ? 'text-gray-400' : 'text-gray-900'}
                    ${errorDateOfBirth || errorAge ? 'border-red-500' : 'border'}`}>
                    {userData['monthOfBirthName'] ? userData['monthOfBirthName'] : t('loan.lender-type-individual-month')}
                    <BiChevronDown className={`text-gray-900 ${openMonth && 'rotate-180'}`}/>
                </div>

                <ul className={`w-[40%] lg:w-[35%] bg-white text-[14px] font-customRegular mt-2 shadow-md overflow-y-auto ${openMonth ? 'absolute max-h-60 border' : 'max-h-0'}`}>
                    {
                        month.map((items)=>{
                            return(
                                <li key={items.id}
                                onClick={()=>{
                                    setUserData({...userData, "monthOfBirth" : items.id, "monthOfBirthName" : items.name})
                                    setOpenMonth(!openMonth)
                                    setErrorDateOfBirth(false)
                                    setErrorAge(false)
                                }}
                                className="p-2 hover:bg-gray-100 cursor-pointer text-[14px] font-customRegular">{items.name}</li>
                            )
                        })
                    }
                </ul>
            </div>

            {/* Day */}
            <div className="w-[25%]" ref={dayMenu}>
                <div onClick={()=>{
                    setOpenDate(!openDate)
                }}
                className={`w-[100%] p-2 bg-white text-[14px] font-customRegular border flex items-center justify-between cursor-pointer rounded ${
                    userData['dayOfBirth'] == null ? 'text-gray-400' : 'text-gray-900'}
                    ${errorDateOfBirth || errorAge ? 'border-red-500' : 'border'}`}>
                    {userData['dayOfBirth'] ? userData['dayOfBirth'] : t('loan.lender-type-individual-day')}
                    <BiChevronDown className={`text-gray-900 ${openDate && 'rotate-180'}`}/>
                </div>

                <ul className={`w-[20%] lg:w-[17%] bg-white text-[14px] font-customRegular mt-2 shadow-md overflow-y-auto ${openDate ? 'absolute max-h-60 border' : 'max-h-0'}`}>
                    {
                        days(userData['yearOfBirth'],userData['monthOfBirth']).map((items)=>{
                            return(
                                <li key={items.key}
                                onClick={()=>{
                                    setUserData({...userData, "dayOfBirth" : items.key})
                                    setOpenDate(!openDate)
                                    setErrorDateOfBirth(false)
                                    setErrorAge(false)
                                }}
                                className="p-2 hover:bg-gray-100 cursor-pointer text-[14px] font-customRegular"
                                >{items.key}</li>
                            )
                        })
                    }
                </ul>
            </div>
        </div>
        <p className={`font-customRegular text-[12px] text-red-500 ${errorDateOfBirth || errorAge ? 'block' : 'hidden'}`}>{
        errorDateOfBirth ? t('lender.error.dateOfBirth') : t('lender.error.age')
        }</p>
        
        <div className="w-[100%] mt-5">
            <input
            value={userData['homeAddress']}
            onChange={(e)=>setUserData({...userData, "homeAddress": e.target.value},
            setErrorAddress(false))}
            className={`appearance-none block text-[14px] font-customRegular w-full bg-white text-gray-900 border rounded py-2 px-2 leading-tight focus:outline-none focus:bg-white ${errorAddress ? 'border-red-500' : 'border'}`} type="text" placeholder={t('loan.lender-type-individual-address')}/>
            <p className={`font-customRegular text-[12px] mt-1 text-red-500 ${errorAddress ? 'block' : 'hidden'}`}>{t('lender.error.homeAddress')}</p>
        </div>

        <div className="grid lg:flex w-[100%] mt-5 gap-0 lg:gap-5">
        {/* City */}
        <div className="w-[100%] lg:w-[40%]" ref={cityMenu}>
                <div onClick={()=>{
                    setOpenCity(!openCity)
                }}
                className={`w-[100%] p-2 bg-white text-[14px] font-customRegular border flex items-center justify-between cursor-pointer rounded ${
                    userData['cityName'] == null ? 'text-gray-400' : 'text-gray-900'}
                    ${errorCity ? 'border-red-500' : 'border'}`}>
                    {userData['cityName'] ? userData['cityName'] : `${t('loan.lender-type-individual-city')}`}
                    <BiChevronDown className={`text-gray-900 ${openCity && 'rotate-180'}`}/>
                </div>

                <ul className={`w-[90%] lg:w-[27%] bg-white text-[14px] font-customRegular mt-2 shadow-md overflow-y-auto ${openCity ? 'absolute max-h-60 border' : 'max-h-0'}`}>
                    <div className="flex items-center px-2 sticky top-0 bg-white">
                        <AiOutlineSearch className="text-gray-500 text-[16px]"/>
                        <input type="text"
                        placeholder={t('loan.lender-type-individual-city-name')}
                        value={cityInputValue}
                        onChange={(e)=>setCityInputValue(e.target.value.toLowerCase())}
                        className={`p-2 w-[100%] text-gray-500 text-[14px] font-customRegular outline-none`}/>
                    </div>
                    {
                        city.map((items)=>{
                            return(
                                <li key={items.cityId}
                                onClick={()=>{
                                    setUserData({...userData, "cityId" : items.cityId, "cityName" : items.cityName})
                                    setOpenCity(!openCity)
                                    setErrorCity(false)
                                }}
                                className={`p-2 hover:bg-gray-100 cursor-pointer text-[14px] font-customRegular
                                ${items?.cityName?.toLowerCase().includes(cityInputValue) ? 'block' : 'hidden'}`}>{items.cityName}</li>
                            )
                        })
                    }
                </ul>
                <p className={`font-customRegular text-[12px] text-red-500 ${errorCity ? 'block' : 'hidden'}`}>{t('lender.error.city')}</p>
            </div>

            {/* Province */}
        <div className="w-[100%] lg:w-[40%] mt-5 lg:mt-0" ref={provinceMenu}>
                <div onClick={()=>{
                    setOpenProvince(!openProvince)
                }}
                className={`w-[100%] p-2 bg-white text-[14px] font-customRegular border flex items-center justify-between cursor-pointer rounded ${
                    userData['provinceName'] == null ? 'text-gray-400' : 'text-gray-900'}
                    ${errorProvince ? 'border-red-500' : 'border'}`}>
                    {userData['provinceName'] ? userData['provinceName'] : `${t('loan.lender-type-individual-province')}`}
                    <BiChevronDown className={`text-gray-900 ${openProvince && 'rotate-180'}`}/>
                </div>

                <ul className={`w-[90%] lg:w-[27%] bg-white text-[14px] font-customRegular mt-2 shadow-md overflow-y-auto ${openProvince ? 'absolute max-h-60 border' : 'max-h-0'}`}>
                    <div className="flex items-center px-2 sticky top-0 bg-white">
                        <AiOutlineSearch className="text-gray-500 text-[16px]"/>
                        <input type="text"
                        placeholder={t('loan.lender-type-individual-province-name')}
                        value={provinceInputValue}
                        onChange={(e)=>setProvinceInputValue(e.target.value.toLowerCase())}
                        className={`p-2 w-[100%] text-gray-500 text-[14px] font-customRegular outline-none`}/>
                    </div>
                    {
                        province.map((items)=>{
                            return(
                                <li key={items.provinceId}
                                onClick={()=>{
                                    setUserData({...userData, "provinceId" : items.provinceId, "provinceName" : items.provinceName})
                                    setOpenProvince(!openProvince)
                                    setErrorProvince(false)
                                }}
                                className={`p-2 hover:bg-gray-100 cursor-pointer text-[14px] font-customRegular
                                ${items?.provinceName?.toLowerCase().includes(provinceInputValue) ? 'block' : 'hidden'}`}>{items.provinceName}</li>
                            )
                        }) 
                    }
                </ul>
                <p className={`font-customRegular text-[12px] text-red-500 ${errorProvince ? 'block' : 'hidden'}`}>{t('lender.error.province')}</p>
            </div>

            <div className="w-[100%] lg:w-[20%]">
                <input
                maxLength={5}
                value={userData['postalCode']}
                onChange={(e)=>setUserData({...userData, "postalCode": e.target.value},
                setErrorPostalCode(false))}
                className={`appearance-none block w-[100%] h-[40px] text-[14px] font-customRegular bg-white text-gray-900 border rounded py-2 px-2 leading-tight focus:outline-none focus:bg-white mt-5 lg:mt-0 ${errorPostalCode ? 'border-red-500' : 'border'}`} type="number" placeholder={t('loan.lender-type-individual-postal-code')}/>
                <p className={`font-customRegular text-[12px] mt-1 text-red-500 ${errorPostalCode ? 'block' : 'hidden'}`}>{t('lender.error.postal.code')}</p>
            </div>
        </div>

        <div className="grid lg:flex w-[100%] gap-5 mt-5">
        {/* Religion */}
        <div className="w-[100%] lg:w-[50%]" ref={religionMenu}>
        <div onClick={()=>{
                setOpenReligion(!openReligion)
            }}
            className={`w-[100%] p-2 bg-white text-[14px] font-customRegular border flex items-center justify-between cursor-pointer rounded ${
                userData['religionName'] == null ? 'text-gray-400' : 'text-gray-900'
            } ${errorReligion ? 'border-red-500' : 'border'}`}>
                {userData['religionName'] ? userData['religionName'] : `${t('loan.lender-type-individual-religion')}`}
                <BiChevronDown className={`text-gray-900 ${openReligion && 'rotate-180'}`}/>
            </div>

            <ul className={`w-[90%] lg:w-[35%] bg-white text-[14px] font-customRegular mt-2 shadow-md overflow-y-auto ${openReligion ? 'absolute max-h-60 border' : 'max-h-0'}`}>
                {
                    religion.map((items)=>{
                        return(
                            <li key={items.id}
                            onClick={()=>{
                                setOpenReligion(!openReligion)
                                setUserData({...userData, "religionId" : items.id, "religionName": items.name})
                                setErrorReligion(false)
                            }}
                            className="p-2 hover:bg-gray-100 cursor-pointer text-[14px] font-customRegular">{items.name}</li>
                        )
                    })
                }
            </ul>
            <p className={`font-customRegular text-[12px] text-red-500 ${errorReligion ? 'block' : 'hidden'}`}>{t('lender.error.religion')}</p>
        </div>

            {/* Education */}
            <div className="w-[100%] lg:w-[50%]" ref={educationMenu}>
                <div onClick={()=>{
                    setOpenEducation(!openEducation)
                }}
                className={`w-[100%] p-2 bg-white text-[14px] font-customRegular border flex items-center justify-between cursor-pointer rounded ${
                    userData['educationName'] == null ? 'text-gray-400' : 'text-gray-900'}
                    ${errorEducation ? 'border-red-500' : 'border'}`}>
                    {userData['educationName'] ? userData['educationName'] : `${t('loan.lender-type-individual-education')}`}
                    <BiChevronDown className={`text-gray-900 ${openEducation && 'rotate-180'}`}/>
                </div>

                <ul className={`w-[90%] lg:w-[35%] bg-white text-[14px] font-customRegular mt-2 shadow-md overflow-y-auto ${openEducation ? 'absolute max-h-60 border' : 'max-h-0'}`}>
                    {
                        education.map((items)=>{
                            return(
                                <li key={items.id}
                                onClick={()=>{
                                    setUserData({...userData, "educationId" : items.id, "educationName" : items.name})
                                    setOpenEducation(!openEducation)
                                    setErrorEducation(false)
                                }}
                                className="p-2 hover:bg-gray-100 cursor-pointer text-[14px] font-customRegular">{items.name}</li>
                            )
                        })
                    }
                </ul>
                <p className={`font-customRegular text-[12px] text-red-500 ${errorEducation ? 'block' : 'hidden'}`}>{t('lender.error.education')}</p>
            </div>
        </div>

            <div className="grid lg:flex w-[100%] mt-5 gap-5">
                <div className="w-[100%] lg:w-[50%]">
                <input 
                    value={userData['email']}
                    onChange={(e)=>setUserData({...userData, "email": e.target.value},
                    setErrorEmail(false))}
                    className={`appearance-none block w-[100%] text-[14px] font-customRegular bg-white text-gray-900 border rounded py-2 px-2 leading-tight focus:outline-none focus:bg-white ${errorEmail ? 'border-red-500' : 'border'}`} type="text" placeholder="Email"/>
                    <p className={`font-customRegular text-[12px] mt-1 text-red-500 ${errorEmail ? 'block' : 'hidden'}`}>{t('lender.error.email')}</p>
                </div>
                <div className="w-[100%] lg:w-[50%]">
                <input
                type="number"
                maxLength={13}
                minLength={9}
                value={userData['phoneNumber']}
                onChange={(e)=>setUserData({...userData, "phoneNumber": e.target.value},
                setErrorPhone(false),
                setErrorPhoneLength(false))}
                className={`appearance-none block w-[100%] text-[14px] font-customRegular bg-white text-gray-900 border rounded py-2 px-2 leading-tight focus:outline-none focus:bg-white ${errorPhone ? 'border-red-500' : 'border'}`} placeholder={t('loan.lender-type-individual-phone')}/>
                <p className={`font-customRegular text-[12px] mt-1 text-red-500 ${errorPhone || errorPhoneLength ? 'block' : 'hidden'}`}>{
                errorPhone ? t('lender.error.phone') : t('lender.error.phone.length')}</p>
                </div>
            </div>

            <div className="flex w-[100%] my-5 gap-5">
                <button class="w-[50%] bg-gray-500 border text-white font-customBold py-2 px-4 rounded shadow-md">
                    <div className="flex items-center justify-center"
                    onClick={()=>(
                        setStep(1)
                    )}>
                        {t('loan.back')}
                    </div>
                </button>

                <button class="w-[50%] bg-[#E77B30] border text-white font-customBold py-2 px-4 rounded shadow-md">
                    <div className="flex items-center justify-center"
                    onClick={()=>(
                        onSubmit()
                    )}>
                        {t('loan.next')}
                    </div>
                </button>
            </div>
        </>
    )
}