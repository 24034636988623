import {GoSearch} from 'react-icons/go'
import activities from './activities.json'
import Poster1 from '../footer/Poster-1'
import { useTranslation } from 'react-i18next'

export default() =>{
    const {t,i18n} = useTranslation()

    const items=[{
        'url':'BACs.jpg',
        'title':<div>Wamendag RI pada ASEAN Business Advisory Council (BAC) Fintech Roundtable Luncheon</div>,
        'desc':<div>Wakil Menteri Perdagangan RI, Jerry Sambuaga menjadi panelis pada sesi pertama ASEAN Business Advisory Council (BAC) Fintech Roundtable Luncheon yang digelar di The Sultan Hotel Jakarta, Rabu (6 Sep).
            <br/>ASEAN Business Advisory Council (BAC) Fintech Roundtable Luncheon mengusung tema "ASEAN Centrality: Innovating towards Greater Inclusivity" dan sesi pertama berfokus pada peran teknologi finansial (tekfin) dalam mempromosikan inklusivitas di ASEAN.</div>,
        'link':'https://www.kemendag.go.id/berita/foto/wamendag-ri-pada-asean-business-advisory-council-bac-fintech-roundtable-luncheon',
        'button': `${t('news.button')}`
    },{
        'url':'news6.png',
        'title':<div>{t('first.news.title5')}</div>,
        'desc':<div>{t('first.news.title5.text')}</div>,
        'link':'https://www.broadcastmagz.com/afpi-bersama-ekosistem-gelar-fintech-sport-day/',
        'button': `${t('news.button')}`
    },{
        'url':'news5.png',
        'title':<div>{t('first.news.title1')}</div>,
        'desc':<div>{t('first.news.title1.text')}</div>,
        'link':'https://bri.co.id/test/-/asset_publisher/G3x3P8wG7JRn/content/bri-apresiasi-mitra-dan-unit-kerja-terbaik-lewat-briapi-awards-2020',
        'button': `${t('news.button')}`
    },{
        'url':'news2.png',
        'title':<div>{t('first.news.title2')}</div>,
        'desc':<div>{t('first.news.title2.text')}</div>,
        'link':'https://infobanknews.com/ini-dia-product-brand-peraih-the-best-digital-brand-2020/',
        'button': `${t('news.button')}`
    },{
        'url':'news3.png',
        'title':<div>{t('first.news.title3')}</div>,
        'desc':<div>{t('first.news.title3.text')}</div>,
        'link':'https://keuangan.kontan.co.id/news/dapat-izin-ojk-fintech-pendanaan-bidik-kerja-sama-dengan-perbankan',
        'button': `${t('news.button')}`
    },{
        'url':'news4.png',
        'title':<div>{t('first.news.title4')}</div>,
        'desc':<div>{t('first.news.title4.text')}</div>,
        'link':'https://www.ojk.go.id/en/berita-dan-kegiatan/pengumuman/Pages/OJK-Certifies-Pendanaan-Teknologi-Nusa-as-Registered-IT-Based-Money-Lending-Services-Provider.aspx',
        'button': `${t('news.button')}`
    }]
    return(
        <>
        <div className='w-[100%] flex justify-center'>
            <div className='w-[100%] pc-3:w-[1366px]'>
                <div className='grid w-[100%] h-fit pt-24'>
                    <h3 className='mb-5 text-[#000] text-center text-[30px] font-customExtraBold'>{t('first.news.title')}</h3>

                    <div className='px-5 md:px-10 lg:px-14'>
                        <div style={{
                            borderRadius: '15px',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundImage: `url(${require('../../assets/images/news1.png')})`
                        }}
                        className='flex w-[100%] min-h-[250px] sm:min-h-[650px] items-end'>
                            <div className='flex w-[100%] h-[75%] items-end px-5 md:px-10 lg:px-14 pb-1 md:pb-5 rounded-xl bg-gradient-to-t from-black to-transparent'>
                            <div className='w-[100%] pb-3 sm:pb-0'>
                                <h3 className='text-white text-[23px] font-customBold line-clamp-1 md:line-clamp-none'>{t('first.news.title5')}</h3>
                                <p className='text-white text-[14px] font-customRegular my-0 md:my-5 line-clamp-2 md:line-clamp-none'>{t('first.news.title5.text')}</p>
                                <a href='https://www.broadcastmagz.com/afpi-bersama-ekosistem-gelar-fintech-sport-day/' target='_blank'
                                className='text-colorOrangeDark text-[14px] font-customBold underline'>{t('news.button')}</a>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='grid md:flex w-[100%] h-fit mt-5 px-5 md:px-10 lg:px-14'>
                    <div className='w-[100%] h-fit pr-0 my-3 md:my-0'>
                        <div className='w-[100%] md:w-[95%]'>
                            <img src={require('../../assets/images/BACs.jpg')} className='w-[100%] min-h-[250px] md:h-[400px] 2xl:h-fit rounded-xl object-cover'/>
                            <h3 className='text-colorBlueDark text-[23px] font-customBold mt-3'>Wamendag RI pada ASEAN Business Advisory Council (BAC) Fintech Roundtable Luncheon</h3>                    
                            <a href='https://www.kemendag.go.id/berita/foto/wamendag-ri-pada-asean-business-advisory-council-bac-fintech-roundtable-luncheon' target='_blank'
                            className='text-colorOrangeDark text-[14px] font-customBold underline'>{t('news.button')}</a>
                        </div>
                    </div>

                    <div className='w-[100%] md:w-[60%]'>
                        <div className='flex items-start'>
                            <img src={require('../../assets/images/news5.png')} className='h-[135px] rounded-xl object-cover'/>
                            <div className='grid h-fit px-3 place-content-between'>
                                <h3 className='text-colorBlueDark text-[14px] font-customBold mb-3 line-clamp-3'>{t('first.news.title1')}</h3>
                                <a href='https://bri.co.id/test/-/asset_publisher/G3x3P8wG7JRn/content/bri-apresiasi-mitra-dan-unit-kerja-terbaik-lewat-briapi-awards-2020' target='_blank'
                                className='text-colorOrangeDark text-[14px] font-customBold underline'>{t('news.button')}</a>
                            </div>
                        </div>

                        <div className='flex mt-5 2xl:mt-14 items-start'>
                            <img src={require('../../assets/images/news2.png')} className='h-[135px] rounded-xl object-cover'/>
                            <div className='grid h-fit px-3 place-content-between'>
                                <h3 className='w-fit h-fit text-colorBlueDark text-[14px] font-customBold mb-3 line-clamp-3'>{t('first.news.title2')}</h3>
                                <a href='https://infobanknews.com/ini-dia-product-brand-peraih-the-best-digital-brand-2020/' target='_blank'
                                className='w-fit h-fit text-colorOrangeDark text-[14px] font-customBold underline'>{t('news.button')}</a>
                            </div>
                        </div>

                        <div className='flex h-fit mt-5 2xl:mt-14 items-start'>
                            <img src={require('../../assets/images/news3.png')} className='h-[135px] rounded-xl object-cover'/>
                            <div className='grid h-fit px-3'>
                                <h3 className='w-fit h-fit text-colorBlueDark text-[14px] font-customBold mb-3 line-clamp-3'>{t('first.news.title3')}</h3>
                                <a href='https://keuangan.kontan.co.id/news/dapat-izin-ojk-fintech-pendanaan-bidik-kerja-sama-dengan-perbankan' target='_blank'
                                className='w-fit h-fit text-colorOrangeDark text-[14px] font-customBold underline'>{t('news.button')}</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='w-[100%] h-fit pt-10 px-5 md:px-10 lg:px-14'>
                    <div className='grid md:flex w-[100%] md:items-center md:justify-center mb-3'>
                        <h3 className='text-[#000] text-center text-[30px] font-customExtraBold'>{t('second.news.title')}</h3>

                        {/* Search */}
                        <div className='hidden md:hidden w-[25%] text-gray-500'>
                            <a href='#' role='button' className='flex w-[100%] h-[50px] items-center justify-between px-5 bg-white border border-solid border-gray-300 rounded-xl font-customRegular'>
                            Cari Berita<GoSearch/></a>
                        </div>
                    </div>
                    
                    {/* Search */}
                    <div className='hidden md:hidden w-[100%] pt-5 text-gray-500'>
                        <a href='#' role='button' className='flex w-[100%] h-[50px] items-center justify-between px-5 bg-white border border-solid border-gray-300 rounded-xl font-customRegular'>
                            Cari Berita<GoSearch/>
                        </a>
                    </div>
                    
                    <div className='grid md:flex md:flex-wrap w-[100%] h-fit pt-5 gap-10 md:justify-between'>
                        {
                            items.map((i)=>{
                                return(
                                    <>
                                    <div className='w-[100%] md:w-[30%] h-fit pb-3'>
                                        <img src={require(`../../assets/images/${i.url}`)} className='w-[100%] min-h-[250px] rounded-xl object-center'/>
                                        <h3 className='w-fit h-fit text-colorBlueDark text-[16px] font-customBold my-3 line-clamp-3'>{i.title}</h3>
                                        <p className='text-[14px] font-customRegular mb-3'>{i.desc}</p>
                                        <a href={i.link} target='_blank'
                                        className='text-colorOrangeDark text-[14px] font-customBold underline'>{i.button}</a>
                                    </div>
                                    </>
                                )
                            })
                        }
                    </div>
                </div>

                <div className='grid md:flex w-[100%] h-fit items-end justify-center pt-[30px] px-5 xl:px-10'>
                    <h3 className='mb-5 text-[#000] text-center text-[30px] font-customExtraBold'>{t('third.news.title')}</h3>
                </div>

                <div className='w-[100%] flex justify-center'>
                    <div className='w-[97%] pb-10'>
                    <table>
                        <tr className='bg-colorOrangeDark text-white'>
                            <th className='py-3'>{t('third.news.title1')}</th>
                            <th className='py-3'>{t('third.news.title2')}</th>
                            <th className='py-3'>{t('third.news.title3')}</th>
                        </tr>
                        {
                            activities.map((item,index)=>{
                                return(
                                    <tr key={index} className='border border-solid border-colorOrangeDark'>
                                        <td className='px-2 py-3'>{item.date}</td>
                                        <td className='px-2 py-3 border border-colorOrangeDark'>{item.name}</td>
                                        <td className='px-2 py-3'>{item.place}</td>
                                    </tr>
                                )
                            })
                        }
                    </table>
                    </div>
                </div>
            </div>
        </div>

        <Poster1/>
        </>
    )
}