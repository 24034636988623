import React, {useRef, useContext, useState} from "react"
import { multiStepContext } from "../Step-Context"
import { FaFilePdf } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'
import { BiChevronDown } from 'react-icons/bi'
import { MdCloudUpload } from 'react-icons/md'
import axios from "axios";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import position from "./position.json"
import validator from 'validator'
import i18next from 'i18next'
import {FaTrash} from 'react-icons/fa'
import '../Application.css'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from "@mui/x-date-pickers"
import dayjs from "dayjs"
import { createTheme, ThemeProvider } from '@mui/material/styles'
import '../Application.css'

const baseURL = "https://pendanaan.com/boss/kta/api/outer/v1/lender/recruit/individual/ektp";

export default()=>{
    const {setStep, userData, setUserData, isPersonal} = useContext(multiStepContext)
    const inputRefEktp = useRef(null)
    const inputRefSelfie = useRef(null)
    const inputRefNpwp = useRef(null)
    const inputRefDeeds = useRef(null)
    const [ektp, setEktp] = useState(null)
    const [selfie, setSelfie] = useState(null)
    const [npwp, setNpwp] = useState(null)
    const [deeds, setDeeds] = useState(null)

    const uploadEktp = ()=>{
        inputRefEktp.current.click()
    }
    
    const ektpChange = async (e) => {
        const file = e.target.files[0]
        setEktp(file)
        setErrorEktpImage(false)
        await uploadEktpApi(e.target.files[0])
    }

    const uploadSelfie = ()=>{
        inputRefSelfie.current.click()
    }

    const selfieChange = async (e) => {
        const file = e.target.files[0]
        setSelfie(e.target.files[0])
        setErrorSelfieImage(false)
        await uploadSelfieApi(e.target.files[0])
    }

    const [openPosition, setOpenPosition] = useState(false)
    const [openPrefix, setOpenPrefix] = useState(false)
    const prefix = [
        {
            prefixId: 0,
            prefixName: "Mr.",

        },{
            prefixId: 1,
            prefixName: "Ms.",
        },{
            prefixId: 2,
            prefixName: "Mrs.",
        }
    ]

    const {t,i18n} = useTranslation()

    async function checkExistingEktp() {
        try{
            await axios
            .post(baseURL, {
              "ektp": userData['ektp']
            }, {
                headers: {
                    'tenant': "KTAID",
                    'Content-Type': "application/json",
                    "Accept-Language": i18next.language
                }
            }).then((response) => {
                if(userData['ektpFileSize'] >= 5000000){
                    setErrorEktpImage(true)
                }else if(userData['selfieFileSize'] >= 5000000){
                    setErrorSelfieImage(true)
                }else{
                    if(response.data.data == true){
                        window.alert(response.data.msg)
                        return;
                      }else{
                        setStep(5)
                      }
                }
              console.log(JSON.stringify(response.data))
            })
        }catch(e){
            console.log("error: " + e)
            return;
        }
    }

    async function uploadEktpApi(file){
        setLoading(true)
        let formData = new FormData();
        formData.append("type", 1);
        formData.append("attachment", file);
        let response = null

        try {
        response = await axios({
            method: "post",
            url: "https://pendanaan.com/boss/kta/api/outer/v1/lender/recruit/upload",
            data: formData,
            timeout: 50000,
            headers: { "Content-Type": "multipart/form-data",
            "tenant": "KTAID",
            "Accept-Language": i18next.language },
        })
        } catch(error) {
            if (error.code === 'ECONNABORTED') {
                setLoading(false)
            }else{
                console.log(error)
            }
        }

        if(!response||response.success == false){
            window.alert(response.data.msg)
        }else{
            setUserData({...userData, "ektpFileSize": file.size, "ektpFileType": file.type, "ektpImgInfo": response.data.data, "ektpFile": file})
        }

        setLoading(false)
        return response.data.data;
    }

    async function uploadSelfieApi(file){
        setLoading(true)
        let formData = new FormData();
        formData.append("type", 1);
        formData.append("attachment", file);
        let response = null
        try {
        response = await axios({
            method: "post",
            url: "https://pendanaan.com/boss/kta/api/outer/v1/lender/recruit/upload",
            data: formData,
            timeout: 50000,
            headers: { 'tenant': "KTAID",
            "Content-Type": "multipart/form-data",
            "Accept-Language": i18next.language },
        })
        } catch(error) {
            if (error.code === 'ECONNABORTED') {
                setLoading(false)
            }else{
                console.log(error)
            }
        }

        console.log("here 6 " + JSON.stringify(response))

        if(!response||response.success == false){
            window.alert(response.data.msg)
        }else{
            setUserData({...userData, "selfieFileSize": file.size, "selfieFileType": file.type, "selfieImgInfo": response.data.data, "selfieFile": file})
        }

        setLoading(false)
        return response.data.data
    }

    const uploadNpwp = ()=>{
        inputRefNpwp.current.click()
    }

    const npwpChange = async (e) => {
        const file = e.target.files[0]
        setNpwp(e.target.files[0])
        setErrorNpwpImage(false)
        await uploadNpwpApi(e.target.files[0])
    }

    async function uploadNpwpApi(file){
        setLoading(true)
        const formData = new FormData();
        formData.append("type", 1);
        formData.append("attachment", file);
        let response = null
        try {
        response = await axios({
            method: "post",
            url: "https://pendanaan.com/boss/kta/api/outer/v1/lender/recruit/upload",
            data: formData,
            timeout: 50000,
            headers: { 'tenant': "KTAID",
            "Content-Type": "multipart/form-data",
            "Accept-Language": i18next.language },
        })
        } catch(error) {
            if (error.code === 'ECONNABORTED') {
                setLoading(false)
            }else{
                console.log(error)
            }
        }

        if(!response||response.success == false){
            window.alert(response.data.msg)
        }else{
            setUserData({...userData, "documentFileSize": file.size, "documentFileType": file.type, "documentImgInfo": response.data.data, "documentFile": file})
        }

        setLoading(false)
        return response.data.data
    }

    const uploadDeeds = ()=>{
        inputRefDeeds.current.click()
    }

    const deedsChange = async (e) => {
        const file = e.target.files[0]
        setDeeds(e.target.files[0])
        setErrorDeedsImage(false)
        await uploadDeedsApi(e.target.files[0])
    }

    async function uploadDeedsApi(file){
        setLoading(true)
        const formData = new FormData();
        formData.append("type", 1);
        formData.append("attachment", file);
        let response = null
        try {
        response = await axios({
            method: "post",
            url: "https://pendanaan.com/boss/kta/api/outer/v1/lender/recruit/upload",
            data: formData,
            timeout: 50000,
            headers: { 'tenant': "KTAID",
            "Content-Type": "multipart/form-data",
            "Accept-Language": i18next.language },
        })
        } catch(error) {
            if (error.code === 'ECONNABORTED') {
                setLoading(false)
            }else{
                console.log(error)
            }
        }

        if(!response||response.success == false){
            window.alert(response.data.msg)
        }else{
            setUserData({...userData, "deedsFileSize": file.size, "deedsFileType": file.type, "deedsImgInfo": response.data.data, "deedsFile": file})
        }

        setLoading(false)
        return response.data.data
    }

    const filterBySize = (file) => {
        return file.size <= 1e+5;
    };

    const [errorEktpImage, setErrorEktpImage] = useState(false)
    const [errorSelfieImage, setErrorSelfieImage] = useState(false)
    const [errorDeedsImage, setErrorDeedsImage] = useState(false)
    const [errorEktp, setErrorEktp] = useState(false)

    const onSubmit=()=>{
        if(userData['ektpImgInfo'] == null){
            setErrorEktpImage(true)
        }else if(userData['selfieImgInfo'] == null){
            setErrorSelfieImage(true)
        }else if(userData['ektp'] == '' || userData['ektp'] == null){
            setErrorEktp(true)
        }else{
            checkExistingEktp()
        }
    }

    const [errorNpwpImage, setErrorNpwpImage] = useState(false)
    const [errorAmended, setErrorAmended] = useState(false)
    const [errorLeaderPrefix, setErrorLeaderPrefix] = useState(false)
    const [errorEktpName, setErrorEktpName] = useState(false)
    const [errorPosition, setErrorPosition] = useState(false)
    const [errorLeaderPhone, setErrorLeaderPhone] = useState(false)
    const [errorLeaderPhoneLength, setErrorLeaderPhoneLength] = useState(false)

    const onSubmitInstitution=()=>{
        if(userData['documentFileSize'] >= 5000000){
            setErrorNpwpImage(true)
        }else if(userData['documentImgInfo'] == null){
            setErrorNpwpImage(true)
        }if(userData['deedsFileSize'] >= 5000000){
            setErrorDeedsImage(true)
        }else if(userData['deedsImgInfo'] == null){
            setErrorDeedsImage(true)
        }else if(userData['amendedDate'] == '' || userData['amendedDate'] == null){
            setUserData({...userData, "amendedDate": dateToString(dayjs().date(), dayjs().month()+1, dayjs().year())})
        }else if(userData['amendedNumber'] == '' || userData['amendedNumber'] == null){
            setErrorAmended(true)
        }else if(userData['prefixId'] == null){
            setErrorLeaderPrefix(true)
        }else if(userData['leaderName'] == '' || userData['leaderName'] == null){
            setErrorEktpName(true)
        }else if(userData['positionId'] == null){
            setErrorPosition(true)
        }else if(userData['leaderPhoneNumber'] == null || userData['leaderPhoneNumber'] == '' || !validator.isMobilePhone(userData['leaderPhoneNumber'])){
            setErrorLeaderPhone(true)
        }else if(userData['leaderPhoneNumber'].toString().length < 9 || userData['leaderPhoneNumber'].toString().length > 13){
            setErrorLeaderPhoneLength(true)
        }else{
            setStep(5)
        }
    }

    const prefixMenu = useRef(null)
    const closePrefixMenu = (e)=>{
        if(prefixMenu.current && openPrefix && !prefixMenu.current.contains(e.target)){
            setOpenPrefix(false)
        }
    }

    document.addEventListener('mousedown',closePrefixMenu)
    
    const positionMenu = useRef(null)
    const closePositionMenu = (e)=>{
        if(positionMenu.current && openPosition && !positionMenu.current.contains(e.target)){
        setOpenPosition(false)
        }
    }
    document.addEventListener('mousedown',closePositionMenu)

    function dateToString(day, month, year) {
        console.log("day=" + day)
        let strDay = day.toString();
        strDay = strDay.length > 1 ? strDay : '0' + strDay; 
        console.log("strDay=" + strDay)

        console.log("month=" + month)
        let strMonth = month.toString();
        strMonth = strMonth.length > 1 ? strMonth : '0' + strMonth;
        console.log("strMonth=" + strMonth)
        
        return year.toString() + "-" + strMonth + "-" + strDay;
    }

    const theme = createTheme({
        typography: {
          fontFamily: ['Plus Jakarta Sans', 'sans-serif'].join(","),
        },
      })
      
    const [loading, setLoading] = useState(false)
    console.log(userData)
    if (loading) {
        return(
            <>
            <div className="loader-container left-0 top-0">
                <div className="spinner"/>
            </div>
            </>
        )
    } else {
    return(
        <>
        {/* Individual */}
        <div className={`${isPersonal ? 'grid' : 'hidden'} w-[100%] h-[100%]`}>
        <span className="text-[14px] font-customBold text-gray-900 mt-10 mb-3">{t('loan.lender-certification-title')}</span>
        <span className="text-[14px] font-customRegular text-gray-900 mb-5">{t('loan.lender-certification-subtitle')}</span>
        <div className="grid lg:flex w-[100%] gap-5">
            <div className="w-[100%] lg:w-[50%] h-[250px]">
            <button
            onClick={()=>{
                setUserData({...userData, "ektpFileSize": null, "ektpFileType": null, "ektpImgInfo": null, "ektpFile": null})
            }} className={`bg-opacity-70 absolute w-[50px] h-[50px] flex items-center justify-center ml-2 mt-2 bg-slate-700 rounded-full ${userData['ektpFile'] ? 'block' : 'hidden'}`}>
                <FaTrash className="w-[25px] h-[25px] text-white"/>
            </button>

            <div className={`w-[100%] h-[100%] rounded-xl border-2 border-dashed border-[#E77B30] flex justify-center items-center cursor-pointer
            ${errorEktpImage ? 'border-red-500' : 'border-[#E77B30]'}`} onClick={uploadEktp}>
                <div className="grid justify-items-center">
                <MdCloudUpload className={`text-[#E77B30] text-[50px] ${userData['ektpFile'] ? 'hidden' : 'block'}`}/>
                <input type="file" ref={inputRefEktp} onChange={ektpChange} className="hidden" accept=".png,.jpg,.jpeg" fileFilter={filterBySize}/>
                <p className={`font-customRegular text-[12px] text-gray-900 text-center mt-2 ${userData['ektpFile'] ? 'hidden' : 'block'}`}>{t('loan.lender-image-supported1')}</p>
                </div>
                <div className={`w-[100%] h-[100%] ${userData['ektpFile'] ? 'block' : 'hidden'}`}>
                    {userData['ektpFile'] ? <img src={URL.createObjectURL(userData['ektpFile'])} alt='user' className="w-[100%] h-[100%] rounded-xl"/>
                    : <MdCloudUpload className="text-[#E77B30] text-[50px]"/>}
                </div>
            </div>
            <p className={`font-customRegular text-[12px] mt-1 text-red-500 ${errorEktpImage ? 'block' : 'hidden'}`}>{t('lender.error.ektp.image')}</p>
            <div className="flex justify-center">
                <span className="text-[14px] text-center font-customBold text-gray-900 mt-5 mb-3">EKTP</span>
                </div>
            </div>

            <div className="w-[100%] lg:w-[50%] h-[250px] mt-14 lg:mt-0">
            <button
            onClick={()=>{
                setUserData({...userData, "selfieFileSize": null, "selfieFileType": null, "selfieImgInfo": null, "selfieFile": null})
            }} className={`bg-opacity-70 absolute w-[50px] h-[50px] flex items-center justify-center ml-2 mt-2 bg-slate-700 rounded-full ${userData['selfieFile'] ? 'block' : 'hidden'}`}>
                <FaTrash className="w-[25px] h-[25px] text-white"/>
            </button>

            <div className={`w-[100%] h-[100%] rounded-xl border-2 border-dashed border-[#E77B30] flex justify-center items-center cursor-pointer
            ${errorSelfieImage ? 'border-red-500' : 'border-[#E77B30]'}`} onClick={uploadSelfie}>
                <div className="grid justify-items-center">
                <MdCloudUpload className={`text-[#E77B30] text-[50px] ${userData['selfieFile'] ? 'hidden' : 'block'}`}/>
                <input type="file" ref={inputRefSelfie} onChange={selfieChange} className="hidden" accept=".png,.jpg,.jpeg" fileFilter={filterBySize}/>
                <p className={`font-customRegular text-[12px] text-gray-900 text-center mt-2 ${userData['selfieFile'] ? 'hidden' : 'block'}`}>{t('loan.lender-image-supported1')}</p>
                </div>
                <div className={`w-[40%] h-[100%] ${userData['selfieFile'] ? 'block' : 'hidden'}`}>
                    {userData['selfieFile'] ? <img src={URL.createObjectURL(userData['selfieFile'])} alt='user' className="w-[100%] h-[100%] rounded-xl"/>
                    : <MdCloudUpload className="text-[#E77B30] text-[50px]"/>}
                </div>
            </div>
            <p className={`font-customRegular text-[12px] mt-1 text-red-500 ${errorSelfieImage ? 'block' : 'hidden'}`}>{t('lender.error.selfie.image')}</p>
            <div className="flex justify-center">
                <span className="text-[14px] text-center font-customBold text-gray-900 mt-5 mb-3">Selfie</span>
                </div>
            </div>
        </div>

        <div className="w-[100%] mt-20 mb-5">
            <input
            value={userData['ektp']}
            onChange={(e)=>setUserData({...userData, "ektp": e.target.value},setErrorEktp(false))}
            className={`appearance-none text-[14px] font-customRegular block w-full bg-white text-gray-900 border rounded py-2 px-2 leading-tight focus:outline-none focus:bg-white
            ${errorEktp ? 'border-red-500' : 'border'}`} type="number" placeholder='EKTP'/>
            <p className={`font-customRegular text-[12px] mt-1 text-red-500 ${errorEktp ? 'block' : 'hidden'}`}>{t('lender.error.ektp')}</p>
        </div>

        <div className="flex w-[100%] mb-5 gap-5">
                <button class="w-[50%] bg-gray-500 border text-white font-customBold py-2 px-4 rounded shadow-md">
                    <div className="flex items-center justify-center"
                    onClick={()=>(
                        isPersonal ? setStep(2) : setStep(3)
                    )}>
                        {t('loan.back')}
                    </div>
                </button>

                <button class="w-[50%] bg-[#E77B30] border text-white font-customBold py-2 px-4 rounded shadow-md">
                    <div className="flex items-center justify-center"
                    // onClick={checkExistingEktp}
                    onClick={onSubmit}
                    >
                        {t('loan.next')}
                    </div>
                </button>
            </div>
        </div>

        {/* Corporate */}
        <div className={`${isPersonal ? 'hidden' : 'grid'} w-[100%] h-[100%]`}>
        <span className="hidden text-[14px] font-customBold text-gray-900 mt-10 mb-3">{t('loan.lender-type-corporate-institution-document-type')}</span>
        <div className="px-0 lg:px-10 hidden">
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={0}
                name="radio-buttons-group">
                <div className="flex justify-between font-customRegular text-[14px] w-[100%]">
                <FormControlLabel
                sx={{
                    '& .MuiFormControlLabel-label':{
                        fontSize: 14
                    }
                }} value={0} control={<Radio
                sx={{
                    '&.Mui-checked': {
                        color: '#F4BA54',
                    },
                    }}/>} onChange={(e)=>setUserData({...userData, "institutionDocumentType": e.target.value})} label={t('loan.lender-type-corporate-institution-document-type1')} />
                <FormControlLabel
                sx={{
                    '& .MuiFormControlLabel-label':{
                        fontSize: 14
                    }
                }} value={1} control={<Radio sx={{
                    '&.Mui-checked': {
                    color: '#F4BA54',
                    },
                }}/>} onChange={(e)=>setUserData({...userData, "institutionDocumentType": e.target.value})} label={t('loan.lender-type-corporate-institution-document-type2')} />
                <FormControlLabel sx={{
                    '& .MuiFormControlLabel-label':{
                        fontSize: 14
                    }
                }} value={2} control={<Radio sx={{
                    '&.Mui-checked': {
                    color: '#F4BA54',
                    },
                }}/>} onChange={(e)=>setUserData({...userData, "institutionDocumentType": e.target.value})} label={t('loan.lender-type-corporate-institution-document-type3')} />
                </div>
            </RadioGroup>
        </div>

        <span className="text-[14px] font-customBold text-gray-900 mt-10 mb-3">{t('loan.lender-type-corporate-institution-upload')}</span>
        <span className="text-[14px] font-customRegular text-gray-900 mb-5">{t('loan.lender-certification-subtitle')}</span>
        <div className="block lg:flex w-[100%] justify-center gap-5">
            <div className="w-[100%] lg:w-[50%] h-[250px]">
            <button
            onClick={()=>{
                setUserData({...userData, "documentFileSize": null, "documentFileType": null, "documentImgInfo": null, "documentFile": null})
            }} className={`bg-opacity-70 absolute w-[50px] h-[50px] flex items-center justify-center ml-2 mt-2 bg-slate-700 rounded-full ${userData['documentFile'] ? 'block' : 'hidden'}`}>
                <FaTrash className="w-[25px] h-[25px] text-white"/>
            </button>

            <div className={`w-[100%] h-[100%] rounded-xl border-2 border-dashed border-[#E77B30] flex justify-center items-center cursor-pointer
            ${errorNpwpImage ? 'border-red-500' : 'border-[#E77B30]'}`} onClick={uploadNpwp}>
                <div className="grid justify-items-center">
                <MdCloudUpload className={`text-[#E77B30] text-[50px] ${userData['documentFile'] ? 'hidden' : 'block'}`}/>
                <input type="file" ref={inputRefNpwp} onChange={npwpChange} className="hidden" accept=".pdf,.png,.jpg,.jpeg" fileFilter={filterBySize}/>
                <p className={`font-customRegular text-[12px] text-gray-900 text-center mt-2 ${userData['documentFile'] ? 'hidden' : 'block'}`}>{t('loan.lender-image-supported2')}</p>
                </div>
                <div className={`w-[100%] h-[100%] ${userData['documentFile'] ? 'block' : 'hidden'}`}>
                    {userData['documentFile'] ?
                    <div className="h-[100%] flex justify-center items-center">
                        {userData['documentFileType'] == 'application/pdf' ? 
                        <FaFilePdf className="text-[#ef4444] text-[50px]"/> :
                        <img src={URL.createObjectURL(userData['documentFile'])} alt='user' className="w-[100%] h-[100%] rounded-xl"/>} 
                    </div>
                    : <MdCloudUpload className="text-[#E77B30] text-[50px]"/>}
                </div>
            </div>
            <p className={`font-customRegular text-[12px] mt-1 text-red-500 ${errorNpwpImage ? 'block' : 'hidden'}`}>{t('lender.error.npwp.image')}</p>
            <div className="flex justify-center">
                <span className="text-[14px] text-center font-customBold text-gray-900 mt-5 mb-3">{t('loan.lender-type-corporate-institution-document-type1')}</span>
                </div>
            </div>

            <div className="w-[100%] lg:w-[50%] h-[250px] mt-14 lg:mt-0">
            <button
            onClick={()=>{
                setUserData({...userData, "deedsFileSize": null, "deedsFileType": null, "deedsImgInfo": null, "deedsFile": null})
            }} className={`bg-opacity-70 absolute w-[50px] h-[50px] flex items-center justify-center ml-2 mt-2 bg-slate-700 rounded-full ${userData['deedsFile'] ? 'block' : 'hidden'}`}>
                <FaTrash className="w-[25px] h-[25px] text-white"/>
            </button>

            <div className={`w-[100%] h-[100%] rounded-xl border-2 border-dashed border-[#E77B30] flex justify-center items-center cursor-pointer
            ${errorDeedsImage ? 'border-red-500' : 'border-[#E77B30]'}`} onClick={uploadDeeds}>
                <div className="grid justify-items-center">
                <MdCloudUpload className={`text-[#E77B30] text-[50px] ${userData['deedsFile'] ? 'hidden' : 'block'}`}/>
                <input type="file" ref={inputRefDeeds} onChange={deedsChange} className="hidden" accept=".pdf,.png,.jpg,.jpeg" fileFilter={filterBySize}/>
                <p className={`font-customRegular text-[12px] text-gray-900 text-center mt-2 ${userData['deedsFile'] ? 'hidden' : 'block'}`}>{t('loan.lender-image-supported2')}</p>
                </div>
                <div className={`w-[100%] h-[100%] ${userData['deedsFile'] ? 'block' : 'hidden'}`}>
                    {userData['deedsFile'] ?
                    <div className="h-[100%] flex justify-center items-center">
                        {userData['deedsFileType'] == 'application/pdf' ? 
                        <FaFilePdf className="text-[#ef4444] text-[50px]"/> :
                        <img src={URL.createObjectURL(userData['deedsFile'])} alt='user' className="w-[100%] h-[100%] rounded-xl"/>} 
                    </div>
                    : <MdCloudUpload className="text-[#E77B30] text-[50px]"/>}
                </div>
            </div>
            <p className={`font-customRegular text-[12px] mt-1 text-red-500 ${errorDeedsImage ? 'block' : 'hidden'}`}>{t('lender.error.deeds.image')}</p>
            <div className="flex justify-center">
                <span className="text-[14px] text-center font-customBold text-gray-900 mt-5 mb-3">{t('loan.lender-type-corporate-institution-document-type4')}</span>
                </div>
            </div>
        </div>

        <span className="text-[14px] font-customBold text-gray-900 mt-16">{t('loan.lender-type-corporate-amended-title')}</span>
        
        <div className="flex gap-5 my-5">
        <div className="w-[100%] lg:w-[50%]">
        <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker defaultValue={dayjs()}
            maxDate={dayjs()}
            views={['day', 'month', 'year']}
            slotProps={{textField: { size: 'small' }}}
            className='text-[14px] font-customRegular'
            sx={{
                width: '100%',
                '& .MuiOutlinedInput-root':{
                    '& fieldset': {
                        borderColor: '#e5e7eb'
                    },
                    '&:hover fieldset': {
                        borderColor: '#e5e7eb'
                        },
                    '&.Mui-focused fieldset': {
                        borderColor: '#e5e7eb'
                        },
                    },
                "& .MuiOutlinedInput-input": {
                    fontSize: 13
                }
            }}
            onChange={(e)=>setUserData({...userData, "amendedDate": dateToString(e.date(), e.month()+1, e.year())})}/>
        </LocalizationProvider>
        </ThemeProvider>
        </div>

            <div className="w-[100%] lg:w-[50%]">
                <input
                type="number"
                value={userData['amendedNumber']}
                onChange={(e)=>setUserData({...userData, "amendedNumber": e.target.value},
                setErrorAmended(false))}
                className={`appearance-none text-[14px] font-customRegular block w-[100%] bg-white text-gray-900 border rounded py-2 px-2 leading-tight focus:outline-none focus:bg-white
                ${errorAmended ? 'border-red-500' : 'border'}`} placeholder={t('loan.lender-type-corporate-amended-number')}/>
                <p className={`font-customRegular text-[12px] mt-1 text-red-500 ${errorAmended ? 'block' : 'hidden'}`}>{t('lender.error.institution.amended.number')}</p>
            </div>
        </div>
        <div className="w-[100%] h-[1px] bg-gray-200"/>
        
        <span className="text-[14px] font-customBold text-gray-900 my-5">{t('loan.lender-type-corporate-leader')}</span>

        <div className="grid lg:flex w-[100%] gap-5">
            {/* Leader prefix */}
            <div className="w-[100%] lg:w-[50%]" ref={prefixMenu}>
                <div onClick={()=>{
                    setOpenPrefix(!openPrefix)
                }}
                className={`w-[100%] p-2 bg-white text-[14px] font-customRegular border flex items-center justify-between cursor-pointer rounded ${
                    userData['prefixName'] == null ? 'text-gray-400' : 'text-gray-900'}
                    ${errorLeaderPrefix ? 'border-red-500' : 'border'}`}>
                    {userData['prefixName'] ? userData['prefixName'] : `${t('loan.lender-type-corporate-institution-prefix')}`}
                    <BiChevronDown className={`text-gray-900 ${openPrefix && 'rotate-180'}`}/>
                </div>

                <ul className={`w-[90%] lg:w-[35%] bg-white text-[14px] mt-2 shadow-md overflow-y-auto ${openPrefix ? 'absolute max-h-60 border' : 'max-h-0'}`}>
                    {
                        prefix.map((items)=>{
                            return(
                                <li key={items.prefixId}
                                onClick={()=>{
                                    setUserData({...userData, "prefixId" : items.prefixId, "prefixName" : items.prefixName})
                                    setOpenPrefix(!openPrefix)
                                    setErrorLeaderPrefix(false)
                                }}
                                className="p-2 hover:bg-gray-100 cursor-pointer text-[14px] font-customRegular">{items.prefixName}</li>
                            )
                        })
                    }
                </ul>
                <p className={`font-customRegular text-[12px] mt-1 text-red-500 ${errorLeaderPrefix ? 'block' : 'hidden'}`}>{t('lender.error.institution.leader.prefix')}</p>
            </div>

        {/* Name on ektp */}
        <div className="w-[100%] lg:w-[50%]">
        <input 
        value={userData['leaderName']}
        onChange={(e)=>setUserData({...userData, "leaderName": e.target.value},
        setErrorEktpName(false))}
        className={`appearance-none block text-[14px] font-customRegular w-[100%] h-10 bg-white text-gray-900 border rounded py-2 px-2 leading-tight focus:outline-none focus:bg-white
        ${errorEktpName ? 'border-red-500' : 'border'}`} type="text" placeholder={t('loan.lender-type-corporate-institution-leader-name')}/>
        <p className={`font-customRegular text-[12px] mt-1 text-red-500 ${errorEktpName ? 'block' : 'hidden'}`}>{t('lender.error.institution.leader.ektp')}</p>
        </div>
        </div>
        
        <div className="grid lg:flex w-[100%] mt-5 gap-5">
        <div className="w-[100%] lg:w-[50%]" ref={positionMenu}>
                <div onClick={()=>{
                    setOpenPosition(!openPosition)
                }}
                className={`w-[100%] p-2 bg-white text-[14px] font-customRegular border flex items-center justify-between cursor-pointer rounded ${
                    userData['positionName'] == null ? 'text-gray-400' : 'text-gray-900'}
                    ${errorPosition ? 'border-red-500' : 'border'}`}>
                    {userData['positionName'] ? userData['positionName'] : `${t('loan.lender-type-corporate-institution-position')}`}
                    <BiChevronDown className={`text-gray-900 ${openPosition && 'rotate-180'}`}/>
                </div>

                <ul className={`w-[90%] lg:w-[35%] bg-white text-[14px] mt-2 shadow-md overflow-y-auto ${openPosition ? 'absolute max-h-60 border' : 'max-h-0'}`}>
                    {
                        position.map((items)=>{
                            return(
                                <li key={items.id}
                                onClick={()=>{
                                    setUserData({...userData, "positionId" : items.id, "positionName" : items.name})
                                    setOpenPosition(!openPosition)
                                    setErrorPosition(false)
                                }}
                                className="p-2 hover:bg-gray-100 cursor-pointer text-[14px] font-customRegular">{items.name}</li>
                            )
                        })
                    }
                </ul>
                <p className={`font-customRegular text-[12px] text-red-500 ${errorPosition ? 'block' : 'hidden'}`}>{t('lender.error.institution.leader.position')}</p>
            </div>

            {/* Leader phone */}
            <div className="w-[100%] lg:w-[50%]">
                <input
                type="number"
                value={userData['leaderPhoneNumber']}
                onChange={(e)=>setUserData({...userData, "leaderPhoneNumber": e.target.value},
                setErrorLeaderPhone(false),
                setErrorLeaderPhoneLength(false))}
                className={`appearance-none block text-[14px] font-customRegular w-[100%] bg-white text-gray-900 border rounded py-2 px-2 leading-tight focus:outline-none focus:bg-white
                ${errorLeaderPhone || errorLeaderPhoneLength ? 'border-red-500' : 'border'}`} placeholder={t('loan.lender-type-individual-phone')}/>
                <p className={`font-customRegular text-[12px] mt-1 text-red-500 ${errorLeaderPhone || errorLeaderPhoneLength ? 'block' : 'hidden'}`}>{
                errorLeaderPhone ? t('lender.error.institution.leader.phone') : t('lender.error.institution.leader.phone.length')}</p>
            </div>
            </div>

            <div className="flex w-[100%] my-5 gap-5">
                <button class="w-[50%] bg-gray-500 border text-white font-customBold py-2 px-4 rounded shadow-md">
                    <div className="flex items-center justify-center"
                    onClick={()=>(
                        isPersonal ? setStep(2) : setStep(3)
                    )}>
                        {t('loan.back')}
                    </div>
                </button>

                <button class="w-[50%] bg-[#E77B30] border text-white font-customBold py-2 px-4 rounded shadow-md">
                    <div className="flex items-center justify-center"
                    onClick={()=>(
                        onSubmitInstitution()
                    )}>
                        {t('loan.next')}
                    </div>
                </button>
            </div>
        </div>
        </>
    )}
}