import React, { useContext, useState } from 'react'
import { Link, useMatch, useResolvedPath } from 'react-router-dom'
import LenderType from "./step/Lender-Type"
import BasicInfoPersonal from "./step/Basic-Info-Personal"
import BasicInfoCorporate from './step/Basic-Info-Corporate'
import Certification from "./step/Certification"
import FundingInfo from "./step/Funding-Info"
import Succes from "./step/Success"
import { Stepper, Step, StepLabel } from "@mui/material"
import { FaMapMarkerAlt, FaPhoneAlt } from 'react-icons/fa'
import { GrMail } from 'react-icons/gr'
import { useTranslation } from 'react-i18next'
import Toggles from '../../../component/toggle/LanguageToggle'
import { multiStepContext } from './Step-Context'
import {FiArrowLeft} from 'react-icons/fi'
import { grey } from '@mui/material/colors'
import { createTheme, ThemeProvider } from '@mui/material/styles'

export default()=>{
    const {currentStep, finalData}=useContext(multiStepContext)

    function showStep(step){
        switch(step){
            case 1:
                return <LenderType/>
            case 2:
                return <BasicInfoPersonal/>
            case 3:
                return <BasicInfoCorporate/>
            case 4:
                return <Certification/>
            case 5:
                return <FundingInfo/>
            case 6:
                return <Succes/>
        }
    }

    const theme = createTheme({
        typography: {
          fontFamily: ['Plus Jakarta Sans', 'sans-serif'].join(","),
        },
      });

    const {t,i18n} = useTranslation()
    return(
        <div className='flex justify-center'>
            <div className='flex justify-center w-[100%] bg-white fixed shadow-lg z-10'>
                <div className='w-[100%] pc-3:w-[1366px]'>
                    <nav className='flex h-[75px] px-5 items-center justify-between'>
                        <div className='flex'>
                            <div className='flex gap-3 items-center'>
                                <FiArrowLeft/>
                                <CustomLink to = '/' className='text-slate-900'>{t('home.lender')}</CustomLink>
                            </div>
                        </div>

                        <Toggles/>
                    </nav>
                </div>
            </div>

            <div className='w-[100%] pc-3:w-[1366px] mt-[75px]'>
                    <h3 className='text-[#000] text-center text-[30px] font-customExtraBold my-10'>{t('loan.title')}</h3>
                    <div className='w-[100%] flex justify-center'>
                        <ThemeProvider theme={theme}>
                            <Stepper style={{width:'100%'}} activeStep={
                                    currentStep < 3 ? currentStep - 1 : currentStep - 2
                                } orientation='horizontal' alternativeLabel>
                                <Step
                                sx={{
                                    '& .MuiStepLabel-root .Mui-active': {
                                        color: '#F4BA54',
                                    },
                                    '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                                    {
                                        color: grey[900],
                                    },
                                    '& .MuiStepLabel-root .Mui-completed': {
                                        color: '#00e676',
                                    },
                                    '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                                    {
                                        color: grey[900],
                                    }
                                }}>
                                    <StepLabel>{t('loan.lender-type')}</StepLabel>
                                </Step>

                                <Step
                                sx={{
                                    '& .MuiStepLabel-root .Mui-active': {
                                        color: '#F4BA54',
                                    },
                                    '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                                    {
                                        color: grey[900],
                                    },
                                    '& .MuiStepLabel-root .Mui-completed': {
                                        color: '#00e676',
                                    },
                                    '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                                    {
                                        color: grey[900],
                                    }
                                }}>
                                    <StepLabel>{t('loan.lender-basic-info')}</StepLabel>
                                </Step>

                                <Step
                                sx={{
                                    '& .MuiStepLabel-root .Mui-active': {
                                        color: '#F4BA54',
                                    },
                                    '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                                    {
                                        color: grey[900],
                                    },
                                    '& .MuiStepLabel-root .Mui-completed': {
                                        color: '#00e676',
                                    },
                                    '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                                    {
                                        color: grey[900],
                                    }
                                }}>
                                    <StepLabel>{t('loan.lender-certification')}</StepLabel>
                                </Step>

                                <Step
                                sx={{
                                    '& .MuiStepLabel-root .Mui-active': {
                                        color: '#F4BA54',
                                    },
                                    '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                                    {
                                        color: grey[900],
                                    },
                                    '& .MuiStepLabel-root .Mui-completed': {
                                        color: '#00e676',
                                    },
                                    '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                                    {
                                        color: grey[900],
                                    }
                                }}>
                                    <StepLabel>{t('loan.lender-funding-info')}</StepLabel>
                                </Step>
                            </Stepper>
                        </ThemeProvider>
                    </div>

                    <div className='p-5'>
                        {showStep(currentStep)}
                    </div>
                </div>
        </div>
    )
}

function CustomLink({ to, children, ...props }){
    const resolvePath = useResolvedPath(to)
    const isActive = useMatch({path: resolvePath.pathname, end: true})
    return(
        <li className = {`${isActive ? 'active' : ''} list-none`}>
            <Link to = {to} {...props}>{children}</Link>
        </li>
    )
}