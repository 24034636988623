import React, { useEffect } from 'react'
import Banner from './banners/Banner'
import Banner2 from './banners/Banner-2'
import Second from './second/Second'
import Third from './third/Third'
import Forth from './forth/Forth'
import Fifth from './fifth/Fifth'
import Sixth from './sixth/Sixth'
import Footer from '../footer/Poster-1'
import Poster1 from '../footer/Poster-1'
import AOS from "aos"
import "aos/dist/aos.css"

export default() =>{
    // useEffect(() => {
    //     AOS.init()
    //     AOS.refresh()
    //   }, [])

    const thirdSectionItems = [{
            'url':'frame-5.png',
            'title':'Tanpa Jaminan',
            'desc':'Dapatkan pinjaman hingga Rp 15 juta untuk memenuhi berbagai kebutuhan keuangan Anda.'
        },
        {
            'url':'frame-6.png',
            'title':'Cepat & Efisien',
            'desc':'Pencairan dana dalam 5 menit, semua dapat dilakukan melalui ponsel Anda.'
        },
        {
            'url':'frame-7.png',
            'title':'Angsuran Fleksibel',
            'desc':'Produk & angsuran yang beragam untuk perencanaan keuangan yang lebih baik bagi peminjam.'
        },
        {
            'url':'frame-8.png',
            'title':'Biaya Rendah',
            'desc':'Pinjaman terjangkau dengan tagihan yang transparan untuk segala kebutuhan darurat.'
        },
        {
            'url':'frame-9.png',
            'title':'Aman & Terpercaya',
            'desc':'Semua data & informasi pelanggan dilindungi, berizin & diawasi oleh OJK.'
        },
        {
            'url':'frame-10.png',
            'title':'Pembayaran Praktis',
            'desc':'Anda dapat membayar pinjaman dengan mudah melalui ATM, online banking, mobile banking atau mini market.'
        }
    ]
    return(
        <>
            <Banner/>
            <Third/>
            <Second/>
            <Fifth/>
            <Sixth/>
            <Poster1/>
        </>
    )
}