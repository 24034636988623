import { BsChevronDown } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'

export default () =>{
    const {t,i18n} = useTranslation()
    const items = [{
        'question':`${t('first.faq.loan.question1.title')}`,
        'answer':<div>{t('first.faq.loan.question1.text1')}<br/>
        {t('first.faq.loan.question1.text2')}<br/>
        {t('first.faq.loan.question1.text3')}<br/>
        {t('first.faq.loan.question1.text4')}</div>
    },{
        'question':`${t('first.faq.loan.question2.title')}`,
        'answer':<div>{t('first.faq.loan.question2.text1')}<br/><br/>{t('first.faq.loan.question2.text2')}</div>,
    },{
        'question':`${t('first.faq.loan.question3.title')}`,
        'answer':<div>{t('first.faq.loan.question3.text1')}</div>,
    },{
        'question':`${t('first.faq.loan.question4.title')}`,
        'answer':<div>{t('first.faq.loan.question4.text1')}</div>
    },{
        'question':`${t('first.faq.loan.question5.title')}`,
        'answer':<div>{t('first.faq.loan.question5.text1')}<br/><br/>{t('first.faq.loan.question5.text2')}</div>
    },{
        'question':`${t('first.faq.loan.question6.title')}`,
        'answer':<div>
            {t('first.faq.loan.question6.text1')}<br/><br/>
            {t('first.faq.loan.question6.text2')}<br/><br/>
            {t('first.faq.loan.question6.text3')}<br/><br/>
            {t('first.faq.loan.question6.text4')}<br/><br/>
            {t('first.faq.loan.question6.text5')}<br/><br/>
            {t('first.faq.loan.question6.text6')}<br/><br/>
            {t('first.faq.loan.question6.text7')}<br/><br/>
            {t('first.faq.loan.question6.text8')}<br/><br/>
            {t('first.faq.loan.question6.text9')}<br/><br/>
            {t('first.faq.loan.question6.text10')}
        </div>
    }]
    return(
        <div className='px-5 lg:px-14 py-5 lg:py-10'>
        {
            items.map((i)=>{
                return(
                    <div className='relative overflow-hidden mb-3 rounded-lg border border-solid border-gray-100 shadow-md'>
                        <input type='checkbox' className='peer absolute inset-0 w-full h-[100%] opacity-0 z-10 cursor-pointer'/>
                        <div className='w-[85%] lg:w-[100%] pl-5 py-5 flex items-center mr-10 text-[14px] font-customBold peer-checked:text-colorOrangeDark'>
                            <h1>{i.question}</h1>
                        </div>

                        <div className='absolute top-[23px] right-5 transition-transform duration-500 rotate-0 peer-checked:-rotate-180'>
                            <BsChevronDown/>
                        </div>

                        <div className='overflow-hidden text-[14px] font-customRegular bg-gray-50 transition-all duration-500 max-h-0 peer-checked:max-h-fit'>
                            <p className='px-5 py-5 border-t'>{i.answer}</p>
                        </div>
                    </div>
                    )})}
        </div>
    )
}